<script lang="ts" src="./RfqReview.ts"></script>

<template>
  <div class="flex flex-col leading-tight">
    <h4 class="mb-1">{{ review.sellerName }}</h4>
    <p class="mb-2 text-neutral-400">
      <m-icon :size="$const.StyleSize.SMALL" :solid="true" :type="$const.IconTypes.FONT_AWESOME" class="mr-1.5" name="clock"/>
      <span class="text-tiny">{{ review.createdAt.timeSinceHumanRead() }}</span>
    </p>
    <p class="mb-3">{{ review.comment.value }}</p>

    <ul class="grid grid-cols-2 gap-3">
      <li>
        <strong class="block mb-1 font-medium leading-tight">{{ $trans.t('app.form.scoreDescription.label') }}</strong>
        <ul class="m-star-list x-small">
          <li v-for="star in 5" :class="['m-star-list__star', star <= review.scoreDescription ? 'text-yellow-300' : 'text-yellow-50']">
            <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" name="star"/>
          </li>
        </ul>
      </li>

      <li>
        <strong class="block mb-1 font-medium leading-tight">{{ $trans.t('app.form.scoreTechpack.label') }}</strong>
        <ul class="m-star-list x-small">
          <li v-for="star in 5" :class="['m-star-list__star', star <= review.scoreTechpack ? 'text-yellow-300' : 'text-yellow-50']">
            <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" name="star"/>
          </li>
        </ul>
      </li>

      <li>
        <strong class="block mb-1 font-medium leading-tight">{{ $trans.t('app.form.scorePrice.label') }}</strong>
        <ul class="m-star-list x-small">
          <li v-for="star in 5" :class="['m-star-list__star', star <= review.scorePrice ? 'text-yellow-300' : 'text-yellow-50']">
            <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" name="star"/>
          </li>
        </ul>
      </li>

      <li>
        <strong class="block mb-1 font-medium leading-tight">{{ $trans.t('app.form.scoreQuantity.label') }}</strong>
        <ul class="m-star-list x-small">
          <li v-for="star in 5" :class="['m-star-list__star', star <= review.scoreQuantity ? 'text-yellow-300' : 'text-yellow-50']">
            <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" name="star"/>
          </li>
        </ul>
      </li>

      <li>
        <strong class="block mb-1 font-medium leading-tight">{{ $trans.t('app.form.scoreSustainability.label') }}</strong>
        <ul class="m-star-list x-small">
          <li v-for="star in 5" :class="['m-star-list__star', star <= review.scoreSustainability ? 'text-yellow-300' : 'text-yellow-50']">
            <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" name="star"/>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>

</style>
