<script lang="ts" src="./SubmitButton.ts"></script>


<template>
  <m-button
    :appearance="$const.StyleAppearance.SECONDARY"
    :iconAfter="iconAfter"
    :iconBefore="isForm ? {name: 'floppy-disk', solid: true, type: $const.IconTypes.FONT_AWESOME, } : iconBefore"
    :isSubmit="true"
    :size="size"
    @click="submit()"
  >
    <!-- This is a list of all submit labels used:
         - Submit
         - Save
         - Update
            - AvatarModal
         - Done
            - CropModal
         - Export
            - ExportModal
         - Add address
            - LocationModal
         - Send offer
            - OfferCreateModal
         - Send invite(s)
            - ProjectSendModal
         - Report
            - ReportCompany
         - Yes
            - RFQCreatePage
         - Verify VAT number
            - VatValidationModal
         - Sign in
            - LoginFrom
         - Send link
            - PasswordRequestForm
         - Reset password
            - PasswordResetFrom
         - Create account
            - RegisterForm
    -->
    {{ $trans.t(label) }}
  </m-button>
</template>


<style lang="scss" scoped>
</style>
