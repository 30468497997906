import Bluebird from "bluebird";
import {Component} from 'vue-facing-decorator';
import {Main} from "@/app/Main";
import {Modal} from "@/app/views/modals/Modal";
import {Lang} from "@/app/lang/Lang";
import {FormData} from "@/app/utils/FormData";
import {FormRules, FormRulesById} from "@/app/utils/FormRules";
import {RequestMethods} from "@/libs/core/constants/RequestMethods";
import {RfqReviewDTO} from "@/app/dto/RfqReviewDTO";
import {Routes} from "@/app/Routes";
import RfqReviewVue from '@/app/views/components/misc/rfq/RfqReview.vue';
import {TranslationDTO} from "@/app/dto/TranslationDTO";
import {SellerProjectDTO} from "@/app/dto/SellerProjectDTO";

type ReviewFormModel = {
    comment: TranslationDTO,
    scoreDescription: number,
    scorePrice: number,
    scoreQuantity: number,
    scoreSustainability: number,
    scoreTechpack: number,
};

@Component({
    components: {
        'm-rfq-review': RfqReviewVue,
    }
})
export default class ProjectReviewModal extends Modal {

    public readonly flags = {
        ...super.flags,
        loading: false,
    };
    public reviewFormData: FormData<ReviewFormModel> = null;
    public project: SellerProjectDTO = null;
    public review: RfqReviewDTO = null;

    public created(): void {
    }

    public save(): void {
        this.submitReview()
    }

    public show(project: SellerProjectDTO, review): void {
        const reviewFormRules: FormRulesById = {};
        reviewFormRules.comment = {originalValue: [FormRules.required(), FormRules.maxLength(255)]};
        reviewFormRules.scoreDescription = [FormRules.required(), FormRules.range(1, 5)];
        reviewFormRules.scorePrice = [FormRules.required(), FormRules.range(1, 5)];
        reviewFormRules.scoreQuantity = [FormRules.required(), FormRules.range(1, 5)];
        reviewFormRules.scoreSustainability = [FormRules.required(), FormRules.range(1, 5)];
        reviewFormRules.scoreTechpack = [FormRules.required(), FormRules.range(1, 5)];

        const reviewForm: ReviewFormModel = {
            comment: new TranslationDTO(),
            scoreDescription: null,
            scorePrice: null,
            scoreQuantity: null,
            scoreSustainability: null,
            scoreTechpack: null,
        };

        this.reviewFormData = new FormData(reviewForm, reviewFormRules);

        if (project) {
            this.project = project;
        }

        if (review) {
            this.review = review;
            this.reviewFormData.model.scoreDescription = review.scoreDescription;
            this.reviewFormData.model.scorePrice = review.scorePrice;
            this.reviewFormData.model.scoreQuantity = review.scoreQuantity;
            this.reviewFormData.model.scoreSustainability = review.scoreSustainability;
            this.reviewFormData.model.scoreTechpack = review.scoreTechpack;
            this.reviewFormData.model.comment = review.comment;
        }

        this.flags.show = true;
    }

    private submitReview(): void {
        if (!this.reviewFormData.validate()) {
            return;
        }

        this.flags.loading = true;

        const payload: Record<string, any> = this.reviewFormData.createPayload();
        payload.projectUuid = this.project.uuid

        let promise: Bluebird<any> = Main.callApi('seller/rfq/review', RequestMethods.POST, payload, RfqReviewDTO);
        promise = promise.then(this.handleSubmitResult.bind(this));
        promise = promise.catch(this.handleError.bind(this));
        promise = promise.finally(() => {
            this.flags.loading = false;
        });
    }

    private handleSubmitResult(): void {
        this.hide(true);
        Main.app.toast.success(Main.trans.t('app.order.leaveReview.success'), Main.trans.t(Lang.t.app.labels.success));
    }

    private handleError(): void {
        Main.app.toast.danger(
            Main.trans.t(Lang.t.app.toast.danger.leaveRfqReview.message),
            Main.trans.t(Lang.t.app.toast.danger.leaveRfqReview.title)
        );

        setTimeout(() => {
            Routes.refreshPage();
        }, 1000)
    }
}
