import {ComponentBase, Prop, Vue} from 'vue-facing-decorator';

@ComponentBase({
    emits: [
        'update:modelValue',
    ],
})
export abstract class FormItem<T> extends Vue {

    @Prop({type: String, default: null})
    public modelValue: T;

    @Prop({type: String, default: null})
    public readonly id: string;

    @Prop({type: Array, default: null})
    public readonly errors: string[];

    @Prop({type: Boolean, default: false})
    public readonly disabled: boolean;

    @Prop({type: String, default: 'center'})
    public readonly alignment: string;

    @Prop({type: String, default: null})
    public readonly dusk: string;

    public value: T = null;

    public created(): void {
        // Watch the model for changes:
        this.$watch(() => this.modelValue, (newValue: T, _oldValue: T) => {
            this.value = newValue;
        });

        this.value = this.modelValue;
    }

    public mounted(): void {
    }

    public updateValue(value: T): void {
        if (this.value === value) {
            // Set value to `undefined` first to trigger responsiveness
            this.value = undefined;
        }

        const oldValue: T = this.value;
        this.value = value;
        this.$emit('update:modelValue', value, oldValue);
    }

}
