import {BaseDTO} from '@/app/dto/BaseDTO';
import {TranslationDTO} from '@/app/dto/TranslationDTO';
import {CastableProperties} from '@/app/utils/Castable';
import {FileDTO} from "@/app/dto/FileDTO";
import {BaseProjectDTO} from "@/app/dto/BaseProjectDTO";

export class RfqServiceDTO extends BaseDTO {

    public createdAt: Date = null;

    public name: string = null;
    public description: TranslationDTO = null;

    public serviceCategory: string = null;
    public targetDeliveryDate: Date = null;

    public offerCount: number = null;

    public attachments: FileDTO[] = null;
    public attachmentsUrl: string = null;

    public project: BaseProjectDTO = null;

    protected static get castParams(): CastableProperties {
        return {
            ...super.castParams,
            description: TranslationDTO,
            targetDeliveryDate: Date,
            attachments: [FileDTO],
            project: BaseProjectDTO,
        };
    }

}
