<script lang="ts" src="./FormError.ts"></script>


<template>

  <p v-if="errors && errors.length" class="text-red-400 text-sm italic mt-2">
    <m-icon :size="$const.StyleSize.SMALL" :solid="true" :type="$const.IconTypes.FONT_AWESOME" class="mr-1.5" name="circle-exclamation"/>
    <span v-html="errors.join('<br />')"></span>
  </p>

</template>


<style lang="scss" scoped>

</style>
