<script lang="ts" src="./BuyerSearchBar.ts"></script>


<template>

  <ais-instant-search
    :insights="true"
    :middlewares="middlewares"
    :search-client="searchClient"
    index-name="seller_products"
  >
    <h3 class="mb-2 text-xl leading-tightest">{{ title }}</h3>
    <p class="mb-4 text-lg leading-tight">{{ $trans.t('pages.buyerDashboard.searchSection.description') }}</p>

    <ais-search-box
      :autofocus="true"
      :class-names="{
        'ais-SearchBox': 'form-field-group x-large dark max-w-3xl',
        'ais-SearchBox-form': 'form-field',
        'ais-SearchBox-input': 'input rounded-md',
      }"
      :placeholder="$trans.t('app.form.buyerSearch.placeholder')"
      :show-loading-indicator="true"
    />

    <p class="mt-2 text-tiny leading-tight">
      {{ $trans.t('app.form.buyerSearch.disclaimer_label') }}
      <m-link :to="{ name: 'rfqWizard' }" class="text-blue-400 underline hover:text-green-400 transition-colors">{{ $trans.t('app.form.buyerSearch.disclaimer_cta') }}</m-link>
    </p>

    <ais-configure
      :filters="'translations.language:' + $store.app.locale"
      :hits-per-page.camel="500"
    />

    <div class="tabs-container">
      <m-form-field-radio-tab
        v-model="searchResultType"
        :val="SearchResultTypes.PRODUCTS"
        class="tab"
        group="searchResults"
      >
        <span>{{ $trans.t('app.radioTabs.products') }}</span>
        <span v-if="productListLength > 0" class="results-count">{{ productListLength }}</span>
      </m-form-field-radio-tab>

      <m-form-field-radio-tab
        v-model="searchResultType"
        :val="SearchResultTypes.SELLERS"
        class="tab"
        group="searchResults"
      >
        <span>{{ $trans.t('app.radioTabs.sellers') }}</span>
        <span v-if="sellerListLength > 0" class="results-count">{{ sellerListLength }}</span>
      </m-form-field-radio-tab>
    </div>

    <m-loading-spinner :loading="flags.loading"/>

    <ais-index
      index-name="company_sellers"
    >
      <ais-hits
        :transform-items="parseSellers"
      >
        <template v-slot="{ items, sendEvent }">
          <!-- KEEP EMPTY SPAN FOR RESULT -->
          <span></span>

          <template
            v-if="searchResultType === SearchResultTypes.SELLERS"
          >
            <ul
              class="flex flex-col flex-1 space-y-4 auto-rows-max mt-4 xs:grid xs:grid-cols-1 xs:space-y-0 xs:gap-4 md:gap-6 sm:grid-cols-2 lg:grid-cols-3 lg:mt-6 2xl:grid-cols-4"
            >
              <li v-if="items.length === 0">
                <em class="leading-tightest text-neutral-60">{{ $trans.t('app.labels.noResults') }}</em>
              </li>

              <template v-for="(seller, index) in items" v-else :key="seller.uuid">
                <m-product-list-support-block v-if="showSupportBlock(index, sellerListLength)"/>

                <li class="flex flex-col">
                  <m-public-seller-card
                    :hasShadow="false"
                    :seller="seller"
                    @click="sendEvent('click', seller.originalObject, 'Seller Clicked')"
                  />
                </li>
              </template>

              <m-product-list-support-block/>
            </ul>
          </template>

        </template>
      </ais-hits>
    </ais-index>

    <ais-hits
      :transform-items="parseProducts"
    >
      <template v-slot="{ items, sendEvent }">
        <!-- KEEP EMPTY SPAN FOR RESULT -->
        <span></span>

        <template
          v-if="searchResultType === SearchResultTypes.PRODUCTS"
        >
          <ul
            class="flex flex-col flex-1 space-y-4 auto-rows-max mt-4 xs:grid xs:grid-cols-1 xs:space-y-0 xs:gap-4 sm:grid-cols-2 md:gap-6 lg:grid-cols-3 lg:mt-6 2xl:grid-cols-4"
          >
            <li v-if="items.length === 0">
              <em class="leading-tightest text-neutral-60">{{ $trans.t('app.labels.noResults') }}</em>
            </li>

            <template v-for="(product, index) in items" v-else :key="product.uuid">
              <m-product-list-support-block v-if="showSupportBlock(index, productListLength)"/>

              <li class="flex flex-col">
                <m-product-card
                  :key="product.uuid"
                  :hasShadow="true"
                  :product="product"
                  @click="sendEvent('click', product.originalObject, 'Product Clicked')"
                />
              </li>
            </template>

            <m-product-list-support-block/>
          </ul>

        </template>
      </template>

    </ais-hits>

  </ais-instant-search>

</template>


<style lang="scss">

.ais-SearchBox-submit {
  visibility: hidden;
}

.ais-SearchBox-reset {
  visibility: hidden;
}

</style>
