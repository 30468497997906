<script lang="ts" src="./FormFieldRadioCard.ts"></script>


<template>
  <label
    :class="[
      'form-field-group',
      {
        'has-error': (errors?.length > 0),
        selected: selected,
        disabled: disabled,
      },
    ]"
    :for="id"
  >
    <div :class="formFieldClassList" @click.prevent="updateValue($refs.radio.value)">
      <div v-if="icon" class="icon-wrapper">
        <m-icon :name="icon.name" :size="$const.StyleSize.XSMALL" :solid="icon.solid" :type="icon.type" class="icon"/>
      </div>

      <slot name="custom-icon"></slot>
      <div class="flex-1">
        <p class="label">{{ label }}</p>
        <p v-if="supportText" class="support-text">{{ supportText }}</p>
      </div>

      <m-button
        :appearance="selected ? $const.StyleAppearance.SECONDARY : $const.StyleAppearance.NEUTRAL_NEW"
        :icon-before="selected ? { name: 'check', solid: true, type: $const.IconTypes.FONT_AWESOME } : null"
        :size="$const.StyleSize.SMALL"
        class="w-full mt-3"
      >
        {{ selected ? $trans.t('app.labels.selected') : $trans.t('app.labels.select') }}
      </m-button>

      <slot></slot>

      <div
        :class="[
          'hidden',
          {
            checked: selected,
          },
        ]"
      >
        <input
          :id="id"
          ref="radio"
          :checked="selected"
          :disabled="disabled"
          :name="group"
          :value="val"
          type="radio"
          @input="updateValue()"
        />
      </div>
    </div>

    <div class="errors">
      <m-form-error :errors="errors"/>
    </div>
  </label>
</template>

<style lang="scss" scoped>

.form-field-group {
  @apply flex flex-1 text-center cursor-pointer;

  .form-field {
    @apply hover:border-green-400 transition-colors;
  }

  &.selected .form-field {
    @apply border-green-400 bg-green-25;

    .icon-wrapper .icon,
    :deep(.icon-wrapper .icon) {
      @apply text-green-400;
    }
  }

  &:hover .form-field .icon-wrapper .icon,
  &:hover .form-field :deep(.icon-wrapper .icon) {
    @apply text-green-400;
  }

  .form-field {
    @apply w-full h-full flex-col justify-center flex-1 p-3 pt-4 space-x-0;

    .icon-wrapper .icon,
    :deep(.icon-wrapper .icon) {
      @apply text-3xl;
    }

    .label {
      @apply mt-4 mb-1 font-bold leading-tight text-center;
    }

    .support-text {
      @apply text-tiny text-neutral-80;
    }

    &.x-small .icon-wrapper .icon,
    &.x-small :deep(.icon-wrapper .icon) {
      @apply text-base;
    }

    &.small .icon-wrapper .icon,
    &.small :deep(.icon-wrapper .icon) {
      @apply text-xl;
    }

    &.medium .icon-wrapper .icon,
    &.medium :deep(.icon-wrapper .icon) {
      @apply text-3xl;
    }

    &.large .icon-wrapper .icon,
    &.large :deep(.icon-wrapper .icon) {
      @apply text-4xl;
    }

    &.x-large .icon-wrapper .icon,
    &.x-large :deep(.icon-wrapper .icon) {
      @apply text-6xl;
    }
  }

  .radio-wrapper {
    @apply hidden;
  }
}

</style>
