<script lang="ts" src="./FormFieldRadioButton.ts"></script>


<template>
  <label
    :class="[
      'form-field-group',
      {
        'has-error': (errors?.length > 0),
        selected: (modelValue === val),
        disabled: disabled,
      },
    ]"
    :for="id"
  >
    <div :class="formFieldClassList" @click.prevent="updateValue($refs.radio.value)">
      <div v-if="icon" class="icon-wrapper">
        <m-icon :name="icon.name" :size="$const.StyleSize.SMALL" :solid="icon.solid" :type="icon.type" class="icon"/>
      </div>
      <span class="label">
        <slot></slot>
      </span>

      <div
        :class="[
          'radio-wrapper inline-flex',
          {
            checked: modelValue === val,
          },
        ]"
      >
        <input
          :id="id"
          ref="radio"
          :checked="modelValue === val"
          :disabled="disabled"
          :name="group"
          :value="val"
          type="radio"
          @input="updateValue()"
        />

        <span class="radio">
          <m-icon :size="$const.StyleSize.XSMALL" :solid="true" :type="$const.IconTypes.FONT_AWESOME" name="circle"/>
        </span>
      </div>
    </div>

    <div class="errors">
      <m-form-error :errors="errors"/>
    </div>
  </label>
</template>


<style lang="scss" scoped src="./FormFieldRadio.scss"></style>
<style lang="scss" scoped src="./FormFieldRadioButton.scss"></style>
<style lang="scss" scoped>

.form-field-group.selected .form-field .icon {
  @apply text-neutral-0;
}

</style>
