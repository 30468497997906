<script lang="ts" src="./MenuItem.ts"></script>


<template>
  <template v-if="hasAccess">
    <li v-if="item.children" class="item">
      <!-- BRANCH -->

      <button :class="['button', { open: flags.open }]" @click.prevent="flags.open = !flags.open">
        <span class="icon">
          <m-icon
            :name="item.icon?.name ?? 'circle'"
            :size="$const.StyleSize.LARGE"
            :solid="item.icon?.solid"
            :type="item.icon?.type ?? $const.IconTypes.FONT_AWESOME"
          />
        </span>
        <transition mode="out-in" name="fade-in">
          <span class="label">{{ item.label }}</span>
        </transition>
        <span v-if="item.notifications && item.notifications > 0" class="notifications">{{ item.notifications }}</span>
        <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" class="chevron" name="chevron-right"/>
      </button>

      <ul v-show="flags.open" :class="['sub-menu']">
        <m-menu-item
          v-for="child in item.children.filter((childItem) => childItem)"
          :item="child"
        />
      </ul>

    </li>

    <li v-else class="item" @click="$emit('menuItemCallback')">
      <!-- LEAF -->

      <m-link :class="['button', { current: isSelected }]" :to="item.link">
        <span class="icon">
          <m-icon
            :name="item.icon?.name ?? 'circle'"
            :size="item.icon?.size ?? $const.StyleSize.LARGE"
            :solid="item.icon?.solid"
            :type="item.icon?.type ?? $const.IconTypes.FONT_AWESOME"
          />
        </span>
        <span class="label">{{ item.label }}</span>
        <span v-if="item.notifications && item.notifications > 0" class="notifications">{{ item.notifications }}</span>
      </m-link>

    </li>
  </template>
</template>


<style lang="scss" scoped>
.item {
  @apply flex flex-col items-start;
}

.button {
  @apply flex flex-1 w-full justify-start items-center px-3 py-2.5 rounded-xl font-medium text-tiny text-neutral-400 transition-colors duration-200 ease-in-out;

  .icon {
    @apply flex items-center justify-center w-5 h-5 mr-2 text-base text-current;
  }

  .label {
    @apply flex-1 text-left leading-tight;
  }

  .notifications {
    @apply min-w-5 h-5 leading-tight flex items-center justify-center px-1 mt-px ml-3 rounded-md font-heading font-bold text-sm text-red-400 bg-red-400 bg-opacity-20;
  }

  .chevron {
    @apply mt-px ml-1 transform text-xs text-neutral-20;
  }

  .icon,
  .notifications,
  .chevron {
    @apply transition-all duration-200 ease-in-out;
  }

  &.open {
    @apply text-neutral-400 bg-neutral-10;

    .chevron {
      @apply text-neutral-60 rotate-90;
    }
  }

  &.current {
    @apply bg-neutral-800 text-neutral-0;

    .chevron {
      @apply text-neutral-0 text-opacity-50;
    }
  }

  &.parent {
    @apply bg-neutral-10;
  }

  &:hover .icon,
  &.open .icon {
    @apply ml-2;
  }
}

.sub-menu {
  @apply flex flex-col w-full mt-2 pl-3 transition-all;

  .button {
    @apply p-2 rounded;

    &:not(.current) {
      @apply hover:bg-neutral-10 hover:text-neutral-400;
    }

    .icon {
      @apply mr-1.5;
    }

    &:hover .icon {
      @apply ml-0;
    }
  }

  .button .icon {
    @apply w-4 h-4 pb-px text-sm;
  }

  .button .label {
    @apply text-tiny leading-none;
  }
}

// Test stuff for when we make the menu expand on hover.
// .item.collapsed .label,
// .item.collapsed .notifications,
// .item.collapsed .chevron {
//   @apply hidden;
// }

// .item.collapsed .icon {
//   @apply mr-0;
// }

// .item.collapsed .button:hover .icon,
// .item.collapsed .button.open .icon {
//   @apply ml-0;
// }

</style>
