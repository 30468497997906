<script lang="ts" src="./FormSelectCardPicker.ts"></script>

<template>
  <div
    :class="[
      'form-field-group',
      size,
      {
        'has-error': (errors?.length > 0),
        'has-data': (value?.length > 0),
        disabled: disabled,
      },
    ]"
  >
    <div v-if="searchable" class="m-input-group mb-3">
      <m-form-label :id="id" :required="required">
        {{ searchLabel ?? $trans.t('app.labels.searchLabel') }}
      </m-form-label>
      <div ref="formField" class="form-field">
        <div class="prepend">
          <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" name="search"/>
        </div>

        <input
          :id="id"
          ref="filterInput"
          v-model="filterStr"
          :disabled="disabled"
          :placeholder="searchPlaceholder ?? $trans.t('app.labels.searchPlaceholder')"
          class="input"
          type="search"
        />
      </div>
    </div>

    <!-- Select options -->
    <div
      v-if="showControls && filteredItems.length"
      class="flex flex-col items-center xs:flex-row xs:-ml-2 xs:-mb-2 pb-3"
    >
      <p class="inline-flex grow order-last text-tiny text-neutral-80 xs:order-first xs:pl-2 xs:pb-2">
        {{ $trans.t('app.labels.numberOfSelected', {count: selectedItems.length, max: allItemsCount}) }}
      </p>

      <div class="inline-flex flex-wrap flex-row items-center -mb-2 -ml-2 pb-2 xs:pl-2">
        <div class="inline-flex pb-2 pl-2">
          <m-button
            :appearance="$const.StyleAppearance.DANGER"
            :icon-before="{ name: 'xmark', solid: true, type: $const.IconTypes.FONT_AWESOME, }"
            :size="$const.StyleSize.SMALL"
            :variant="$const.StyleVariants.TEXT"
            class="ml-1.5"
            @click.prevent="deselectAllItems()"
          >
            {{ $trans.t('components.selectPicker.clearAll') }}
          </m-button>
        </div>

        <div class="inline-flex pb-2 pl-2">
          <m-button
            :appearance="$const.StyleAppearance.NEUTRAL"
            :size="$const.StyleSize.SMALL"
            :variant="$const.StyleVariants.OUTLINE"
            @click.prevent="selectAllItems()"
          >
            {{ $trans.t('components.selectPicker.selectAll') }}
          </m-button>
        </div>
      </div>
    </div>


    <m-form-error :errors="errors" class="errors"/>

    <!-- Select items -->
    <ul :class="[
      'flex flex-col p-3 space-y-3 overflow-auto rounded-xl border xs:grid xs:grid-cols-2 xs:gap-3 xs:space-y-0',
      (errors?.length > 0) ? 'border-red-100 bg-red-50' : 'border-neutral-20 bg-smoke-0',
      gridCols,
    ]">
      <!-- List items: -->
      <li
        v-for="item in filteredItems"
        :key="item.id"
        :class="{ 'col-span-full': item.id === undefined }"
      >
        <div
          :class="[
            'select-item group',
            {
              checked: checkItemSelected(item),
            }
          ]"
          :dusk="item.id"
          @click="clickItem(item)"
        >
          <input
            :checked="checkItemSelected(item)"
            :disabled="disabled"
            :type="(multiselect) ? 'checkbox' : 'radio'"
            class="hidden"
          />

          <m-icon
            v-if="!omitItemIcons && item.icon"
            :class="['icon group-hover:text-green-400', iconClass]"
            :name="item.icon?.name"
            :size="$const.StyleSize.LARGE"
            :solid="item.icon?.solid"
            :type="item.icon?.type"
          />

          <p class="text-tiny font-medium">{{ item.label ?? item.id }}</p>
        </div>
      </li>

      <!-- More/less buttons: -->
      <li v-if="filteredItems.length == 0" class="col-span-full text-center">
        <em class="leading-tightest text-neutral-60">{{ $trans.t('app.labels.noResults') }}</em>
      </li>
    </ul>
  </div>
</template>


<style lang="scss" scoped src="./FormFieldCheckbox.scss"></style>
<style lang="scss" scoped src="./FormFieldRadio.scss"></style>
<style lang="scss" scoped>

.has-error .form-field {
  @apply border-neutral-20;
}

.errors {
  @apply -mt-2 mb-2;
}

.select-item {
  @apply h-full flex flex-col items-center px-2 py-3 text-center leading-tightest rounded border border-neutral-20 bg-neutral-0 hover:bg-green-25 hover:border-green-75 active:bg-green-50 cursor-pointer transition-colors duration-200 ease-in-out xs:min-h-24 xs:p-2 xs:pt-4;

  .icon {
    @apply mb-2 leading-none transition-colors xs:mb-3;
  }

  &.checked {
    @apply bg-green-25 border-green-400;

    .icon {
      @apply text-green-400;
    }
  }
}

</style>
