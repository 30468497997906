/**
 * Style: Sizes.
 */
export const enum StyleSize {

    XXSMALL = 'xx-small',
    XSMALL = 'x-small',
    SMALL = 'small',
    MEDIUM = 'medium',
    LARGE = 'large',
    XLARGE = 'x-large',
    XXLARGE = 'xx-large',

}
