<script lang="ts" src="./FormFieldCheckboxButton.ts"></script>


<template>
  <label
    :class="[
      'form-field-group',
      {
        'has-error': (errors?.length > 0),
        selected: value,
        disabled: disabled,
      },
    ]"
    :for="id"
  >
    <div :class="['form-field', size]" @click="$refs.checkbox.checked = !$refs.checkbox.checked">
      <slot name="icon"></slot>
      <template v-if="tooltip">
        <m-tooltip :label="tooltip" class="w-full">
          <span class="label">
            <slot></slot>
          </span>
        </m-tooltip>
      </template>
      <template v-else>
        <span class="label">
          <slot></slot>
        </span>
      </template>

      <div
        :class="[
          'inline-flex',
          {
            checked: value,
          },
        ]"
      >
        <input
          :id="id"
          ref="checkbox"
          :checked="value"
          :disabled="disabled"
          :name="id"
          type="checkbox"
          @input="handleInput($event)"
        />

        <span class="checkbox">
          <m-icon :size="$const.StyleSize.SMALL" :solid="true" :type="$const.IconTypes.FONT_AWESOME" name="check"/>
        </span>
      </div>
    </div>

    <div class="errors">
      <m-form-error :errors="errors"/>
    </div>
  </label>
</template>


<style lang="scss" scoped src="./FormFieldCheckbox.scss"></style>
<style lang="scss" scoped src="./FormFieldCheckboxButton.scss"></style>
<style lang="scss" scoped>

.form-field-group.selected .form-field ::v-slotted(.icon) {
  @apply text-neutral-0;
}

</style>
