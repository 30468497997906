<script lang="ts" src="./FormFieldCheckbox.ts"></script>

<template>
  <label
    :class="[
      'form-field-group',
      {
        'has-error': (errors?.length > 0),
        disabled: disabled,
      },
    ]"
    :for="id"
  >
    <div
      class="form-field form-field--checkbox"
      @click="$refs.checkbox.checked = !$refs.checkbox.checked"
    >
      <div
        :class="[
          'inline-flex',
          {
            checked: value,
          },
        ]"
      >
        <input
          :id="id"
          ref="checkbox"
          :checked="value"
          :disabled="disabled"
          :name="id"
          type="checkbox"
          @input="handleInput($event)"
        />

        <span class="checkbox">
          <m-icon :size="$const.StyleSize.SMALL" :solid="true" :type="$const.IconTypes.FONT_AWESOME" name="check"/>
        </span>
      </div>

      <span class="label">
        <slot></slot>
      </span>

    </div>

    <div class="errors">
      <m-form-error :errors="errors"/>
    </div>
  </label>
</template>


<style lang="scss" scoped src="./FormFieldCheckbox.scss"></style>
<style lang="scss" scoped>
</style>
