<script lang="ts" src="./FormSelectAvailabilityStatusPicker.ts"></script>


<template>
  <div
    :id="id"
    v-click-outside="close"
    :class="[
      'form-field-group',
      size,
      {
        'has-error': (errors?.length > 0),
        'has-data': (value?.length > 0),
        disabled: disabled,
        opened: flags.opened,
      },
    ]"
  >
    <!-- Input field -->
    <div ref="formField" :class="['form-field', disabled ? 'disabled' : '']">
      <m-button
        ref="formField"
        :appearance="$const.StyleAppearance.DARK"
        :variant="$const.StyleVariants.OUTLINE"
        @click.prevent="handleFieldClick($event)"
      >
        <m-icon :class="['mr-2', iconColor(selectedItem)]" :size="$const.StyleSize.SMALL" :solid="true" :type="$const.IconTypes.FONT_AWESOME" name="circle-dot"/>
        <span>{{ selectedLabel ?? $trans.t('app.form.availabilityStatus.placeholder') }}</span>
      </m-button>
    </div>

    <!-- Dropwdown -->
    <transition mode="out-in" name="slide-from-top-fade">
      <div v-if="flags.opened" :class="['dropdown overflow-hidden', direction]">
        <ol class="flex flex-col max-h-40 overflow-y-auto">
          <!-- List items: -->
          <li
            v-for="item in items"
            :key="item.id"
            :class="['item', {
              checked: checkItemSelected(item),
            }]"
            :dusk="'availability-' + item.id"
            @click="clickItem(item)"
          >
            <div class="hidden">
              <input
                :checked="checkItemSelected(item)"
                :disabled="disabled"
                type="radio"
              />
            </div>

            <m-icon :class="['mr-2', iconColor(item)]" :size="$const.StyleSize.SMALL" :solid="true" :type="$const.IconTypes.FONT_AWESOME" name="circle-dot"/>
            <span>{{ item.label }}</span>
          </li>
        </ol>
      </div>
    </transition>
  </div>
</template>


<style lang="scss" scoped src="./FormFieldRadio.scss"></style>
<style lang="scss" scoped>

.dropdown {
  @apply flex flex-col min-w-max absolute right-0 top-full mt-1 rounded-xl border bg-neutral-0 transition-all duration-300 ease-in-out shadow-xl;
  z-index: 1;

  .item {
    @apply flex items-center flex-1 px-4 py-1 leading-snug hover:bg-smoke-0 hover:text-neutral-800 cursor-pointer transition-colors duration-200 ease-in-out;

    &.checked {
      @apply bg-neutral-10 text-neutral-800;
    }
  }
}

.form-field {
  @apply border-none;
}

</style>
