<script lang="ts" src="./Link.ts"></script>


<template>

  <a :class="clss" :href="href" @click.prevent="handleClick($event)">
    <slot></slot>
    <m-icon
      v-if="clipboardValue"
      :size="$const.StyleSize.SMALL"
      :solid="false"
      :type="$const.IconTypes.FONT_AWESOME"
      class="ml-0.5"
      name="copy"
    />
  </a>

</template>


<style lang="scss" scoped>

</style>
