import {Component, Prop, Vue} from 'vue-facing-decorator';
import {RouteLocationRaw} from 'vue-router';
import {Routes} from '@/app/Routes';
import {Main} from "@/app/Main";

@Component({})
export default class Link extends Vue {

    @Prop({type: String, default: null})
    public readonly class: string;

    @Prop({type: [String, Object], required: false,})
    private readonly to: RouteLocationRaw;

    @Prop({type: Boolean, default: false,})
    private readonly newWindow: boolean;

    @Prop({type: String, default: null})
    private readonly clipboardValue: string;

    public get href(): string {
        if (typeof this.to == 'string') {
            return (this.to.startsWith('call::') || this.to.startsWith('download::')) ? '#' : this.to;
        } else {
            return Routes.resolve(this.to).href;
        }
    }

    public get clss(): string {
        let clss = this.class;

        if (this.clipboardValue) {
            clss = clss + ' m-link';
        }

        return clss;
    }

    public created(): void {
    }

    public mounted(): void {
    }

    public handleClick(event: MouseEvent): void {
        if (this.clipboardValue) {
            Main.copyToClipboard(this.clipboardValue);
            Main.app.toast.success(
                'Successfully copied to clipboard!',
                'Copied'
            );

            return;
        }

        if (this.to) {
            Routes.resolveLink(this.to, event.ctrlKey || this.newWindow);
        }

        return;
    }

}
