import {Component, Prop, Vue} from "vue-facing-decorator";
import {RouteLocationRaw} from "vue-router";
import {StyleSize} from "@/app/constants/Constants";
import {FormButtonLabels} from "@/app/constants/FormButtonLabels";

@Component({
    emits: [
        'cancel',
    ]
})
export default class CancelButton extends Vue {

    @Prop({type: [String, Object], default: null})
    public readonly to: RouteLocationRaw;

    @Prop({type: String, default: FormButtonLabels.CANCEL})
    public readonly label: string;

    @Prop({type: String, default: StyleSize.MEDIUM})
    public readonly size: string;

    @Prop({type: Boolean, default: false})
    public readonly isForm: Boolean;

    public cancel(): void {
        this.$emit('cancel');
    }

}
