import Bluebird from 'bluebird';
import {Component, Watch} from 'vue-facing-decorator';
import {Main} from '@/app/Main';
import {Routes} from '@/app/Routes';
import {Settings} from '@/app/Settings';
import {DeviceTypes, StyleSize} from '@/app/constants/Constants';
import {SellerProjectDTO} from '@/app/dto/SellerProjectDTO';
import {SellerRfqProductDTO} from '@/app/dto/SellerRfqProductDTO';
import {Lang} from '@/app/lang/Lang';
import {Store} from '@/app/stores/Store';
import {FormData} from '@/app/utils/FormData';
import {FormRules, FormRulesById} from '@/app/utils/FormRules';
import {Modal} from '@/app/views/modals/Modal';
import {RequestMethods} from '@/libs/core/constants/RequestMethods';
import {Uuid} from '@/types/Types';
import {Roles} from "@/app/constants/Roles";
import {SellerRfqServiceDTO} from "@/app/dto/SellerRfqServiceDTO";
import {IconParseTypes} from "@/app/constants/IconParseTypes";

type OfferFormModel = {
    projectUuid: Uuid,
    collaborationUuid: Uuid,
    deliveryDate?: Date,
    message?: string,
    products: {
        uuid: Uuid,
        price?: number,
        quantity?: number,
    }[],
    services: {
        uuid: Uuid,
    }[],
};

@Component({})
export default class OfferCreateModal extends Modal {

    public readonly flags = {
        ...super.flags,
        loading: false,
    };

    public readonly IconParseTypes = IconParseTypes;

    public formData: FormData<OfferFormModel> = null;
    public project: SellerProjectDTO = null;

    private topProductUuid: Uuid = null;
    private topServiceUuid: Uuid = null;

    /**
     * Get the number of products that have been filled (not validated per se!).
     */
    public get numNewOffers(): number {
        return this.formData.model.products.filter((value) => {
            return (value !== null && value !== undefined)
        }).length + this.formData.model.services.filter((value) => {
            return (value !== null && value !== undefined)
        }).length;
    }

    public get isMobile(): boolean {
        return Store.app.device === DeviceTypes.MOBILE;
    }

    public get formFieldSize(): StyleSize {
        return this.isMobile ? StyleSize.XSMALL : StyleSize.MEDIUM;
    }

    public get productIsLast(): boolean {
        return this.topServiceUuid !== null;
    }

    public created(): void {
    }

    public mounted(): void {
    }

    public show(projectUuid: Uuid, collaborationUuid: Uuid, productUuid: Uuid = null, serviceUuid: Uuid = null): void {
        if (projectUuid && collaborationUuid) {
            throw new Error('The bid modal accepts either a projectUuid or a collaborationUuid, not both!');
        } else if (!projectUuid && !collaborationUuid) {
            throw new Error('The bid modal requires either a projectUuid or a collaborationUuid!');
        }

        this.topProductUuid = productUuid;
        this.topServiceUuid = serviceUuid;

        const payload: Record<string, any> = {};
        payload.projectUuid = projectUuid;
        payload.collaborationUuid = collaborationUuid;

        const route: string = (projectUuid) ? 'seller/rfq' : 'collaboration/offer';

        let promise: Bluebird<any> = Main.callApi(route, RequestMethods.GET, payload, SellerProjectDTO);
        promise = promise.then(this.handleRfqResult.bind(this));
        promise = promise.catch(this.handleRfqError.bind(this));
    }

    public hide(force: boolean = false): boolean {
        const success: boolean = super.hide(force);
        Routes.addQueryArguments({rfqUuid: undefined}, true);
        return success;
    }

    public addProductOffer(index: number): void {
        if (!this.formData.model.products[index]) {
            this.formData.model.products[index] = {uuid: this.project.products[index].uuid};
        }
    }

    public addServiceOffer(index: number): void {
        if (!this.formData.model.services[index]) {
            this.formData.model.services[index] = {uuid: this.project.services[index].uuid};
        }
    }

    public toggleProductOffer(index: number): void {
        if (!this.formData.model.products[index]) {
            this.addProductOffer(index)
            return;
        }

        if (this.formData.model.products[index]) {
            delete this.formData.model.products[index];
            return;
        }
    }

    public toggleServiceOffer(index: number): void {
        if (!this.formData.model.services[index]) {
            this.addServiceOffer(index)
            return;
        }

        if (this.formData.model.services[index]) {
            delete this.formData.model.services[index];
            return;
        }
    }

    public submit(): void {
        const formIsValid: boolean = this.formData.validate();
        if (!formIsValid) {
            return;
        }

        let promise = Main.app.alert.show(
            Main.trans.t(Lang.t.app.bidModal.confirm.msg),
            Main.trans.t(Lang.t.app.bidModal.confirm.title),
            Main.trans.t(Lang.t.app.bidModal.confirm.yes),
            Main.trans.t(Lang.t.app.bidModal.confirm.no)
        );
        promise = promise.then(() => {
            const payload: Record<string, any> = this.formData.createPayload();

            this.flags.loading = true;
            let promise: Bluebird<any> = Main.callApi('collaboration/offer', RequestMethods.POST, payload);
            promise = promise.then(this.handleResult.bind(this));
            promise = promise.catch(this.handleError.bind(this));
            promise = promise.then(() => {
                this.flags.loading = false;
            });
            promise = promise.finally(this.hide.bind(this));
        });
        promise = promise.catch((err: Error) => {
            if (err) {
                throw err;
            }

        });
    }

    public totalPrice(product: SellerRfqProductDTO, index: number): number {
        if (this.formData.model.products[index]?.quantity || this.formData.model.products[index]?.price) {
            if (!this.formData.model.products[index]?.quantity || !this.formData.model.products[index]?.price) {
                return 0;
            }

            return this.formData.model.products[index].quantity * this.formData.model.products[index].price;
        }

        if (product.quantity && product.price) {
            return product.quantity * product.price;
        }

        return product.targetQuantity * product.targetPrice;
    }

    public openProjectReviewModal(): void {
        Main.app.openProjectReviewModal(this.project);
    }

    @Watch('formData', {deep: true})
    private handleFormChange(newValue: FormData<OfferFormModel>, oldValue: FormData<OfferFormModel>): void {
        const isReset: boolean = (oldValue !== newValue);
        this.flags.formChanged = (!isReset);
    }

    private handleRfqResult(result: SellerProjectDTO): void {
        // Move the topProduct to the front of the array:
        if (this.topProductUuid) {
            result.products.sort((a: SellerRfqProductDTO, b: SellerRfqProductDTO) => {
                return (a.uuid == this.topProductUuid) ? -1 : 0;
            });
        }
        if (this.topServiceUuid) {
            result.services.sort((a: SellerRfqServiceDTO, b: SellerRfqServiceDTO) => {
                return (a.uuid == this.topServiceUuid) ? -1 : 0;
            });
        }

        this.project = result;

        this.flags.show = true;

        // define rules:
        const formRules: FormRulesById = {};
        formRules.deliveryDate = [FormRules.required()];
        formRules.message = [FormRules.maxLength(2000)];

        formRules.products = [FormRules.omitFurtherRules(() => {
            return this.formData.model.services.length > 0;
        }), FormRules.minLength(1)];
        formRules.products._ = {};
        formRules.products._.price = [FormRules.required(), FormRules.min(0)];
        formRules.products._.quantity = [FormRules.required(), FormRules.min(1)];

        formRules.services = [FormRules.omitFurtherRules(() => {
            return this.formData.model.products.length > 0;
        }), FormRules.minLength(1)];
        formRules.services._ = {};

        // Reset form:
        this.formData = new FormData({projectUuid: this.project.uuid, collaborationUuid: this.project.collaborationUuid, products: [], services: []}, formRules);
        this.formData.model.deliveryDate = this.project.deliveryDate;

        // For test only:
        if (Settings.debugMode) {
            this.formData.model.deliveryDate = this.formData.model.deliveryDate ?? new Date();
            this.formData.model.message = 'Test 1.. 2.. 3..';
        }

        // Open the top product:
        if (this.topProductUuid) {
            this.addProductOffer(0);    // Since the top product is always the first anyway
        }

        // Open the top service:
        if (this.topServiceUuid) {
            this.addServiceOffer(0);    // Since the top product is always the first anyway
        }
    }

    private handleRfqError(result: any): void {
        if (!Store.user.hasRoles(Roles.SELLER_TIER_1)) {
            Routes.resolveLink({name: 'sellerSubscriptionList'});
            return;
        }

        Main.app.toast.danger(
            Main.trans.t(Lang.t.app.toast.danger.quotationNotFound.message),
            Main.trans.t(Lang.t.app.toast.danger.quotationNotFound.title)
        );
    }

    private handleResult(result: any): void {
        this.flags.formChanged = false;

        let promise = Main.app.toast.success(
            Main.trans.t(Lang.t.app.toast.success.offerPlaced.message),
            Main.trans.t(Lang.t.app.toast.success.offerPlaced.title)
        );
        promise = promise.then(() => {
            this.hide();
            Routes.resolveLink({name: 'chat', query: {threadUuid: result.threadUuid}});
        });
        promise = promise.catch((err: Error) => {
            if (err) {
                throw err;
            }

            this.hide();
        });
    }

    private handleError(result: any): void {
        Main.app.toast.danger(
            Main.trans.t(Lang.t.app.toast.danger.generalError.message),
            Main.trans.t(Lang.t.app.toast.danger.generalError.title)
        );
    }

}
