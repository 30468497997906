import { param } from 'jquery';


<script lang="ts" src="./Breadcrumbs.ts"></script>


<template>

  <ul class="breadcrumbs">
    <li v-for="level in levelList" class="item">
      <m-link v-if="level.link" :to="{ name: level.link, params: level.params }">
        {{ level.title }}
      </m-link>
      <span v-else>
          {{ level.title }}
        </span>
      <m-icon :size="$const.StyleSize.XSMALL" :solid="true" :type="$const.IconTypes.FONT_AWESOME" class="item-divider" name="angle-right"/> <!-- TODO: remove in case of last last li-instance -->
    </li>
  </ul>

</template>


<style lang="scss" scoped>
.breadcrumbs {
  @apply flex flex-row text-tiny;

  .item {
    @apply flex flex-row items-center;

    .item-divider {
      @apply mx-2;
    }

    &:last-of-type {
      @apply text-blue-400;

      .item-divider {
        @apply hidden;
      }
    }
  }
}

</style>
