export const enum ServiceCategoryTypes {
    DESIGN_DEVELOPMENT = 'design_development',
    SOURCING = 'sourcing',
    MANUFACTURING = 'manufacturing',
    PACKAGING_LABELING = 'packaging_labeling',
    LOGISTICS_SHIPPING = 'logistics_shipping',
    REPAIR = 'repair',
    RESALE = 'resale',
    RECYCLE = 'recycle',
    TRACEABILITY = 'traceability',
    REPORTING = 'reporting',
}
