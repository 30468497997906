import {Component, Prop, Vue} from "vue-facing-decorator";
import {StyleSize} from "@/app/constants/Constants";
import {FormButtonLabels} from "@/app/constants/FormButtonLabels";
import {IIcon} from "@/app/views/components/misc/Icon";

@Component({
    emits: [
        'submit',
    ]
})
export default class SubmitButton extends Vue {

    @Prop({type: String, default: FormButtonLabels.SAVE})
    public readonly label: string;

    @Prop({type: String, default: StyleSize.MEDIUM})
    public readonly size: string;

    @Prop({type: Object, default: null})
    public readonly iconBefore: IIcon;

    @Prop({type: Object, default: null})
    public readonly iconAfter: IIcon;

    @Prop({type: Boolean, default: false})
    public readonly isForm: Boolean;

    public submit(): void {
        this.$emit('submit');
    }

}
