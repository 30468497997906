<script lang="ts" src="./Layout.ts"></script>

<template>
  <div :class="['site-holder', { 'with-sidenav': $store.app.sideMenuVisible, 'full-screen': $store.app.fullHeight, 'supports-flexbox-gap': supportsFlexboxGap, }]">

    <div
      v-if="$store.app.sideMenuVisible"
      :class="['backdrop', { 'sidenav-opened': $store.app.sideMenuOpened }]"
      @click="toggleSideMenu(false)"
    ></div>
    <div
      v-else
      :class="['backdrop', { 'sidenav-opened': $store.app.frontOfficeSideMenuOpened }]"
      @click="toggleFrontOfficeSideMenu(false)"
    ></div>

    <!-- Dashboard side menu: -->
    <nav v-if="$store.app.sideMenuVisible" :class="['sidenav', { opened: $store.app.sideMenuOpened }]">

      <header class="header">
        <m-link :to="{ name: 'dashboard' }">
          <img alt="Manufy" class="logo" src="@/assets/logo_full.svg"/>
        </m-link>

        <span class="inline-flex sm:hidden">
          <FormLanguagePickerVue
            v-model="$store.app.locale"
            :items="$store.meta.locales"
          />
        </span>

        <span class="inline-flex order-first sm:order-last md:hidden">
          <m-button
            :appearance="$const.StyleAppearance.PRIMARY"
            :shape="$const.StyleShapes.SQUARE"
            :size="$const.StyleSize.MEDIUM"
            :variant="$const.StyleVariants.GHOST"
            @click="toggleSideMenu()"
          >
            <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" name="xmark"/>
          </m-button>
        </span>
      </header>

      <ul class="menu space-y-2">
        <template v-if="$store.user.isBuyer">
          <li class="flex">
            <m-button
              :appearance="$const.StyleAppearance.SECONDARY"
              :icon-before="{ name: 'circle-plus', solid: true, type: $const.IconTypes.FONT_AWESOME, }"
              :size="$const.StyleSize.MEDIUM"
              :to="{ name: 'rfqWizard' }"
              class="flex-1"
            >
              {{ $trans.t('app.labels.startProject') }}
            </m-button>
          </li>
        </template>

        <template v-if="$store.user.isSeller && $store.company.slug">
          <li class="flex">
            <m-button
              :appearance="$const.StyleAppearance.SECONDARY"
              :icon-before="{ name: 'user-circle', solid: true, type: $const.IconTypes.HERO, }"
              :size="$const.StyleSize.MEDIUM"
              :to="{ name: 'publicSellerRead', params: { uuid: $store.user.currentCompanyUuid } }"
              class="flex-1"
            >
              {{ $trans.t('pages.sellerDashboard.publicProfileBtn') }}
            </m-button>
          </li>
        </template>

        <m-menu-item
          v-for="(item, index) in $store.app.boStructure"
          :item="item"
        />
      </ul>

      <div v-if="$store.user.isBuyer" class="flex flex-col m-3 p-4 leading-tight rounded bg-purple-50">
        <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" class="hidden 2xl:block 2xl:self-start 2xl:leading-none 2xl:text-xl 2xl:text-purple-300" name="circle-info"/>
        <h6 class="text-lg capitalize 2xl:mt-3">{{ $trans.t('app.dailyDemoNotice.title') }}</h6>
        <p class="mt-2">{{ $trans.t('app.dailyDemoNotice.description') }}</p>

        <m-link :new-window="true" :to="$settings.urlManufyWebinar" class="mt-3 text-tiny underline text-purple-300">
          <span>{{ $trans.t('app.dailyDemoNotice.btnLabel') }}</span>
          <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" class="ml-1.5 text-sm" name="chevron-right"/>
        </m-link>
      </div>
    </nav>

    <div class="page-holder">

      <nav v-if="$store.app.topMenuVisible" class="main-nav">
        <div class="flex flex-1 items-center justify-between">

          <template v-if="!$store.app.sideMenuVisible">
            <div class="flex">
              <div class="hidden xs:inline-flex">
                <m-link :to="logoLinkTarget">
                  <img alt="Manufy" class="logo" src="@/assets/logo_full.svg"/>
                </m-link>
              </div>
              <div class="xs:hidden">
                <m-link :to="logoLinkTarget">
                  <img alt="Manufy" class="logo" src="@/assets/logo.svg"/>
                </m-link>
              </div>
            </div>
          </template>

          <template v-if="$store.app.sideMenuVisible">
            <span class="flex md:hidden">
              <m-button
                :appearance="$const.StyleAppearance.PRIMARY"
                :shape="$const.StyleShapes.SQUARE"
                :variant="$const.StyleVariants.OUTLINE"
                @click="toggleSideMenu()"
              >
                <m-icon :size="$const.StyleSize.LARGE" :solid="false" :type="$const.IconTypes.HERO" name="bars-3"/>
              </m-button>
            </span>
          </template>

          <div class="inline-flex flex-1 justify-end">

            <!-- Logged in user: -->
            <template v-if="$store.user.user">
              <ul class="menu">
                <li class="item ml-2.5">
                  <FormLanguagePickerVue
                    v-model="$store.app.locale"
                    :dusk="'language-picker-dropdown'"
                    :items="$store.meta.locales"
                  />
                </li>

                <li v-if="!$store.user.isAdmin && $store.app.device !== $const.DeviceTypes.MOBILE" class="item">
                  <m-form-select-availability-status-picker
                    id="layoutAvailabilityStatus"
                    v-model="$store.company.availabilityStatus"
                    :dusk="'availability-status-picker-dropdown'"
                    :items="availabilityStatuses"
                  />
                </li>

                <li v-if="$store.app.device === $const.DeviceTypes.MOBILE && !$store.user.isAdmin" class="item">
                  <m-button
                    :appearance="$const.StyleAppearance.DARK"
                    :shape="$const.StyleShapes.SQUARE"
                    :variant="$const.StyleVariants.OUTLINE"
                    class="m-intercom-launcher"
                  >
                    <m-icon :size="$const.StyleSize.LARGE" :solid="false" :type="$const.IconTypes.HERO" name="question-mark-circle"/>
                  </m-button>
                </li>

                <li v-if="!$store.user.isGuest && $store.company.unreadMessagesCount != null" class="item">
                  <m-button
                    :appearance="$const.StyleAppearance.DARK"
                    :shape="$const.StyleShapes.SQUARE"
                    :to="{ name: 'chat' }"
                    :variant="$const.StyleVariants.OUTLINE"
                  >
                    <m-icon :size="$const.StyleSize.LARGE" :solid="false" :type="$const.IconTypes.HERO" name="chat-bubble-oval-left-ellipsis"/>
                  </m-button>
                  <span v-if="$store.company.unreadMessagesCount != 0" class="notification"></span>
                </li>

                <li :dusk="'profile-menu'" class="item">
                  <span class="flex flex-row items-center min-w-max cursor-pointer xs:ml-1" @click="openAvatarMenu()">
                    <span class="hidden xs:inline-flex xs:flex-col xs:mr-2.5 xs:leading-none xs:text-right">
                      <strong class="text-tiny mb-0.5">{{ `${$store.user.user.firstName} ${$store.user.user.lastName}` }}</strong>

                      <small class="text-neutral-60">{{ $store.user.subtitle }}</small>
                    </span>

                    <m-avatar
                      :appearance="$const.StyleAppearance.PRIMARY"
                      :interactive="true"
                      :shape="$const.StyleShapes.DEFAULT"
                      :size="$const.StyleSize.SMALL"
                      :url="$store.company.avatar?.originalUrl"
                    />
                  </span>

                  <transition mode="out-in" name="slide-from-top-fade">
                    <ul v-if="flags.showAvatarMenu" v-click-outside="closeAvatarMenu" class="user-dropdown">

                      <li class="dropdown-item">
                        <m-link :to="{ name: 'dashboard' }" class="link" @click="closeAvatarMenu()">
                          <m-icon :size="$const.StyleSize.MEDIUM" :solid="false" :type="$const.IconTypes.HERO" class="inline-flex mr-2 mb-0.5" name="home"/>
                          <span class="text-tiny">{{ $trans.t('app.profileMenu.myDashboard') }}</span>
                        </m-link>
                      </li>

                      <li class="dropdown-item">
                        <m-link :to="{ name: 'userDetails' }" class="link" @click="closeAvatarMenu()">
                          <m-icon :size="$const.StyleSize.MEDIUM" :solid="false" :type="$const.IconTypes.HERO" class="inline-flex mr-2 mb-0.5" name="user-circle"/>
                          <span class="text-tiny">{{ $trans.t('app.profileMenu.myProfile') }}</span>
                        </m-link>
                      </li>

                      <li class="dropdown-item">
                        <m-link :newWindow="true" :to="$settings.urlMainSite" class="link" @click="closeAvatarMenu()">
                          <m-icon :size="$const.StyleSize.MEDIUM" :solid="false" :type="$const.IconTypes.HERO" class="inline-flex mr-2 mb-0.5" name="globe-alt"/>
                          <span class="text-tiny">{{ $trans.t('app.profileMenu.backToManufy') }}</span>
                        </m-link>
                      </li>

                      <li class="dropdown-item"><span class="divider"></span></li>

                      <li class="dropdown-item">
                        <m-link :to="{ name: 'seoHome' }" class="link" dusk="logout-button" @click="$store.user.logout()">
                          <m-icon :size="$const.StyleSize.MEDIUM" :solid="false" :type="$const.IconTypes.HERO" class="inline-flex mr-2 mb-0.5" name="arrow-left-on-rectangle"/>
                          <span class="text-tiny">{{ $trans.t('app.labels.logout') }}</span>
                        </m-link>
                      </li>
                    </ul>
                  </transition>
                </li>
              </ul>
            </template>

            <!-- Logged out user: -->
            <template v-else>
              <ul class="menu front-office">

                <li class="item inline-flex">
                  <FormLanguagePickerVue
                    v-model="$store.app.locale"
                    :dusk="'language-picker-dropdown'"
                    :items="$store.meta.locales"
                  />
                </li>

                <li v-if="showSignInLink" class="item hidden xs:inline-flex">
                  <m-button
                    :appearance="$const.StyleAppearance.DARK"
                    :size="$const.StyleSize.SMALL"
                    :variant="$const.StyleVariants.OUTLINE"
                    @click="login()"
                  >
                    {{ $trans.t('app.labels.login') }}
                  </m-button>
                </li>

                <li v-if="showSignUpLink" class="item hidden xs:inline-flex">
                  <m-button
                    :dusk="'register-button'"
                    :size="$const.StyleSize.SMALL"
                    :variant="$const.StyleAppearance.SECONDARY"
                    @click="register()"
                  >
                    {{ $trans.t('app.labels.joinNow') }}
                  </m-button>
                </li>

                <li v-if="showSignUpLink" class="item inline-flex xs:hidden">
                  <m-button
                    :size="$const.StyleSize.SMALL"
                    :variant="$const.StyleAppearance.SECONDARY"
                    @click="register()"
                  >
                    {{ $trans.t('app.labels.joinNow') }}
                  </m-button>
                </li>

              </ul>
            </template>
          </div>
        </div>
      </nav>

      <main class="content">
        <router-view v-slot="{ Component }">
          <!-- NOTE This transition becomes buggy the content/page has more than 1 root element (it can't even have a comment-element next to the root element!) -->
          <transition mode="out-in" name="fade-transform">
            <component :is="Component" :key="$route.path"/>
          </transition>
        </router-view>
      </main>

      <!-- <footer class="footer">
        <div class="footer__item">
          <img alt="Manufy" class="logo" src="@/assets/logo_full.svg" />
          <p>Sustainable Fashion Sourcing in the EU. Join the movement!</p>

          <ul class="flex gap-4">
            <li>
              <m-button
                :appearance="$const.StyleAppearance.PRIMARY"
                :shape="$const.StyleShapes.CIRCLE"
                :variant="$const.StyleVariants.OUTLINE"
              ><m-icon :solid="false" :type="$const.IconTypes.FONT_AWESOME_BRANDS" name="facebook-f" /></m-button>
            </li>
            <li>
              <m-button
                :appearance="$const.StyleAppearance.PRIMARY"
                :shape="$const.StyleShapes.CIRCLE"
                :variant="$const.StyleVariants.OUTLINE"
              ><m-icon :solid="false" :type="$const.IconTypes.FONT_AWESOME_BRANDS" name="instagram" /></m-button>
            </li>
            <li>
              <m-button
                :appearance="$const.StyleAppearance.PRIMARY"
                :shape="$const.StyleShapes.CIRCLE"
                :variant="$const.StyleVariants.OUTLINE"
              ><m-icon :solid="false" :type="$const.IconTypes.FONT_AWESOME_BRANDS" name="linkedin-in" /></m-button>
            </li>
          </ul>
        </div>

        <div class="footer__item">
          <h6 class="text-xl">Newsletter</h6>
          <p>Subscribe for the latest news, updates and offers!</p>

          <m-form-field type="email" id="email" placeholder="Enter your email">
            <template #prepend>
              <div class="prepend">
                <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" name="envelope" />
              </div>
            </template>
          </m-form-field>

          <div class="inline-flex self-end">
            <m-button :appearance="$const.StyleAppearance.PRIMARY" icon-before="fas paper-plane">Submit</m-button>
          </div>
        </div>

        <div class="footer__item">
          <h6 class="text-xl">Sitemap</h6>
          <ul class="flex flex-col gap-2">
            <li>About us</li>
            <li>About us</li>
            <li>About us</li>
            <li>About us</li>
            <li>About us</li>
          </ul>
        </div>
      </footer> -->
    </div>
  </div>
</template>

<style lang="scss" scoped>

.backdrop {
  @apply block w-screen h-screen-dynamic fixed z-40 bg-neutral-800 opacity-0 invisible pointer-events-none md:hidden;
}

.sidenav {
  @apply flex flex-col w-screen h-screen-dynamic fixed transform -translate-x-full z-40 bg-neutral-0 sm:w-56 sm:border-r;

  &.front-office {
    @apply w-full right-0 translate-x-full xs:w-1/2;
  }

  .header {
    @apply flex items-center justify-between h-14 p-3 border-b;
  }

  .menu {
    @apply flex flex-col flex-1 p-3 overflow-y-auto;
  }
}

.site-holder,
.backdrop,
.sidenav,
.page-holder {
  @apply transition-all duration-300 ease-in-out;
}

.page-holder {
  @apply flex flex-col;
}

.main-nav {
  @apply h-14 px-3 py-2 flex items-center justify-between sticky top-0 z-30 border-b bg-neutral-0;

  .menu {
    @apply flex items-center space-x-4;

    &.front-office {
      @apply space-x-3;
    }

    &.links {
      @apply hidden ml-4 md:flex lg:space-x-6 lg:ml-6 2xl:space-x-8 2xl:ml-8;
    }

    &:not(.front-office) .item {
      @apply inline-flex relative;

      .notification {
        @apply w-4 h-4 absolute -top-1 -right-1 rounded-full border-3 border-neutral-0 bg-red-400;
      }

      .text {
        @apply hover:text-green-400;
      }
    }
  }

  .user-dropdown {
    @apply min-w-max absolute right-0 top-full mt-4 py-1 rounded-xl border bg-neutral-0 transition-all duration-300 ease-in-out shadow-xl;

    .dropdown-item {
      @apply flex;

      .link {
        @apply flex-1 px-4 py-1 hover:bg-blue-25 hover:text-blue-400 transition-colors duration-200 ease-in-out;
      }
    }

    .divider {
      @apply w-full h-px my-1 bg-neutral-10;
    }
  }
}

.logo {
  @apply h-6 rounded-none md:h-7;
}

// When the side navigation is opened
.sidenav.opened {
  @apply translate-x-0;
}

.site-holder.full-screen {
  @apply h-screen-dynamic max-h-screen flex flex-col;

  .page-holder,
  .page-holder .content {
    @apply flex flex-1 flex-col items-stretch;
    height: calc(theme('height.full') - theme('height.14'));
  }
}

.site-holder.with-sidenav .page-holder {
  @apply md:ml-56;
}

.backdrop.sidenav-opened {
  @apply opacity-75 visible pointer-events-auto;
}

// When the side navigation is square
.sidenav.square {
  @apply w-20;
}

.footer {
  @apply container flex flex-col max-w-full gap-6 sm:flex-row sm:items-start sm:gap-12;

  .footer__item {
    @apply flex flex-1 flex-col gap-4;
  }
}

</style>
