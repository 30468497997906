<script lang="ts" src="./AdminCompanyFilters.ts"></script>

<template>
  <m-form-field-wrapper
    id="companyName"
    v-model="filterData.model.name"
    :label="$trans.t('app.form.companyName.label')"
    :placeholder="$trans.t('app.form.companyName.placeholder')"
    :size="$const.StyleSize.SMALL"
  />

  <m-form-field-wrapper
    id="email"
    v-model="filterData.model.email"
    :label="$trans.t('app.form.email.label')"
    :placeholder="$trans.t('app.form.email.placeholder')"
    :size="$const.StyleSize.SMALL"
  />

  <m-form-field-wrapper
    id="city"
    v-model="filterData.model.city"
    :label="$trans.t('app.form.city.label')"
    :placeholder="$trans.t('app.form.city.placeholder')"
    :size="$const.StyleSize.SMALL"
  />

  <m-form-select-picker-wrapper
    id="countries"
    v-model="filterData.model.countries"
    :dropdownMaxCols="1"
    :items="$store.meta.countries"
    :label="$trans.t('app.form.country.label')"
    :placeholder="$trans.t('app.form.country.placeholder')"
    :size="$const.StyleSize.SMALL"
    multiselect
  />

  <div class="flex flex-row space-x-2">
    <m-form-select-picker-wrapper
      id="timeframe"
      v-model="filterData.model.timeframe"
      :dropdownMaxCols="1"
      :items="createdAtTimeframe"
      :label="$trans.t('app.form.createdAtFilter.timeframePicker')"
      :size="$const.StyleSize.SMALL"
      class="flex flex-1"
    />

    <m-form-field-wrapper
      id="createdAt"
      v-model="filterData.model.createdAt"
      :label="$trans.t('app.form.createdAtFilter.datePicker')"
      :size="$const.StyleSize.SMALL"
      class="w-10"
      type="date"
    />
  </div>

  <m-form-select-picker-wrapper
    id="categories"
    v-model="filterData.model.categories"
    :dropdownMaxCols="1"
    :items="$store.meta.categories"
    :label="$trans.t('app.form.categories.label')"
    :placeholder="$trans.t('app.form.categories.placeholder')"
    :size="$const.StyleSize.SMALL"
    multiselect
  />

  <m-form-select-picker-wrapper
    id="yearlyRevenue"
    v-model="filterData.model.annualTurnover"
    :dropdownMaxCols="1"
    :items="$store.meta.annualTurnovers"
    :label="$trans.t('app.form.yearlyRevenue.label')"
    :placeholder="$trans.t('app.form.yearlyRevenue.placeholder')"
    :size="$const.StyleSize.SMALL"
    multiselect
  />

  <m-form-select-picker-wrapper
    id="totalEmployees"
    v-model="filterData.model.totalEmployees"
    :dropdownMaxCols="1"
    :items="$store.meta.totalEmployees"
    :label="$trans.t('app.form.totalEmployees.label')"
    :placeholder="$trans.t('app.form.totalEmployees.placeholder')"
    :size="$const.StyleSize.SMALL"
    multiselect
  />

  <div class="w-full flex gap-x-3">
    <m-form-select-picker-wrapper
      id="sortColumn"
      v-model="filterData.model.sortColumn"

      :dropdownMaxCols="1"
      :items="sortByColumn"
      :label="$trans.t('app.form.sortByColumn.label')"
      :placeholder="$trans.t('app.form.sortByColumn.placeholder')"
      :size="$const.StyleSize.SMALL"
      class="flex-1"
    />

    <m-form-select-picker-wrapper
      id="sortDirection"
      v-model="filterData.model.sortDirection"
      :dropdownMaxCols="1"
      :items="sortDirection"

      :label="$trans.t('app.form.sortDirection.label')"
      :placeholder="$trans.t('app.form.sortDirection.placeholder')"
      :size="$const.StyleSize.SMALL"
      class="flex-1"
    />
  </div>

  <div class="m-bo-input-group">
    <label class="label">Verified Only</label>
    <m-form-field-checkbox-button v-model="filterData.model.isVerified" :size="$const.StyleSize.SMALL">
      <template>
        <span class="icon-wrapper">
          <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" class="icon" name="compass-drafting"/>
        </span>
      </template>
      {{ $trans.t('pages.sellerDashboard.filters.isVerified') }}
    </m-form-field-checkbox-button>
  </div>

  <div class="m-bo-input-group">
    <label class="label">Open To Feedback</label>
    <m-form-field-checkbox-button v-model="filterData.model.isOpenToFeedback" :size="$const.StyleSize.SMALL">
      <template>
        <span class="icon-wrapper">
          <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" class="icon" name="compass-drafting"/>
        </span>
      </template>
      Open to feedback
    </m-form-field-checkbox-button>
  </div>

  <div class="m-bo-input-group">
    <label class="label">Is deleted</label>
    <m-form-field-checkbox-button v-model="filterData.model.isDeleted" :size="$const.StyleSize.SMALL">
      <template>
        <span class="icon-wrapper">
          <m-icon :solid="true" :type="$const.IconTypes.FONT_AWESOME" class="icon" name="compass-drafting"/>
        </span>
      </template>
      Is deleted
    </m-form-field-checkbox-button>
  </div>

  <m-form-select-picker-wrapper
    v-if="!isOnboarding"
    id="subscriptionStatus"
    v-model="filterData.model.subscriptionStatus"
    :dropdownMaxCols="1"
    :items="subscriptionStatus"
    :label="$trans.t('app.form.subscriptionStatus.label')"
    :placeholder="$trans.t('app.form.subscriptionStatus.placeholder')"
    :size="$const.StyleSize.SMALL"
    multiselect
  />

  <m-form-select-picker-wrapper
    v-if="availabilityStatus"
    id="availabilityStatus"
    v-model="filterData.model.availabilityStatus"
    :dropdownMaxCols="1"
    :items="availabilityStatus"
    :label="$trans.t('app.form.availabilityStatus.label')"
    :placeholder="$trans.t('app.form.availabilityStatus.adminPlaceholder')"
    :size="$const.StyleSize.SMALL"
    multiselect
  />
</template>

<style lang="scss" scoped>

</style>
