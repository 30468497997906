import {createRouter, createWebHistory, LocationQuery, RouteLocation, RouteLocationNormalizedLoaded, RouteLocationRaw, RouteMeta, Router, RouteRecordRaw, RouterOptions} from 'vue-router';
import {Main} from '@/app/Main';
import {Roles} from '@/app/constants/Roles';
import {AuthDTO} from '@/app/dto/AuthDTO';
import {Gate, GateResult} from '@/app/gates/Gate';
import {Lang} from '@/app/lang/Lang';
import {Store} from '@/app/stores/Store';
import {DownloadHelper} from '@/app/utils/DownloadHelper';
import {SegmentHelper} from '@/app/utils/SegmentHelper';
import Layout from '@/app/views/Layout.vue';
import {Translations} from '@/app/lang/Translations';
import {Settings} from "@/app/Settings";

export class Routes {

    private static redirectCounter: number = 0;
    private static routerInst: Router = null;

    /**
     * Get the router instance.
     */
    public static get router(): Router {
        if (!Routes.routerInst) {
            const routerOptions: RouterOptions = {
                history: createWebHistory(),
                routes: Routes.getRoutes(),
                scrollBehavior(to, from, savedPosition) {
                    return savedPosition || ((to.hash) ? {el: to.hash, behavior: 'smooth',} : {top: 0,});
                },
            };

            Routes.routerInst = createRouter(routerOptions);
            Routes.routerInst.beforeEach(Routes.handleBeforeEach);
            Routes.routerInst.afterEach(Routes.handleAfterEach);

            Store.user.authChangedSignal.connect(Routes.handleAuthChanged);
        }

        return Routes.routerInst;
    }

    /**
     * Get the current route.
     */
    public static get currentRoute(): RouteLocationNormalizedLoaded {
        return Routes.routerInst?.currentRoute.value;
    }

    /**
     * Get locale based on the current url.
     */
    public static get localeFromUrl(): string {
        // Parse directly from window.location since it might be requested before Routes.routerInst is instantiated.
        const localePrefix: string = window.location.pathname.split('/')[1];
        return (Object.keys(Lang.localesNative).includes(localePrefix)) ? localePrefix : null;
    }

    /**
     * Check if the current user has access to the given route.
     * This will check the roles, route-gate and user verification.
     * The method is read-only so nothing will be set or changed.
     */
    public static checkAccess(to: RouteLocation): true | RouteLocationRaw {
        const meta: RouteMeta = (to?.meta) ? to.meta : {};

        // Check roles:
        const requiredRoles: Roles[] = <Roles[]>meta.roles;
        const userRoles: Roles[] = (Store.user.user) ? Store.user.user.roles : [];

        // By default, routs are accessible to anyone.
        // If there are requiredRoles, check if the user has at least one of them:
        const rolesValid: boolean = (!requiredRoles || requiredRoles.haveCommon(userRoles));
        if (rolesValid) {
            if (meta.omitVerification) {
                // Check if the route has a gate:
                if (meta.gate instanceof Function) {
                    return (<() => GateResult>meta.gate)();
                }
            }

            return true;
        }
        // User has improper roles; check how to resolve:
        // Check if it's the COMPANY_USER role that's missing:
        if (Store.user.user) { // User is logged in
            return {name: 'dashboard'};
        } else {  // User is not logged in
            return {name: 'seoHome'};
        }
    }

    /**
     * Resolve and go to the given link.
     * @param to The link to resolve. Either a string or RouteLocation object.
     * @param newWindow Whether to open the link in a new window. In case of a link to an external site it will always be opened in an external window.
     * @param refresh Whether to refresh the page.
     */
    public static resolveLink(to: RouteLocationRaw, newWindow: boolean = false, refresh: boolean = false): void {
        if (typeof to === 'string' && to.startsWith('call::')) {
            Main[to.substring(6)]();
        } else if (typeof to === 'string' && to.startsWith('download::')) {
            const url: string = to.substring(10);
            DownloadHelper.downloadUrl(url);
        } else if (typeof to === 'string' && (to.startsWith('https://') || to.startsWith('http://') || to.startsWith('ftp://') || to.startsWith('mailto:'))) {
            window.open(to, (newWindow) ? '_blank' : '_self');
        } else {
            const route = Routes.resolve(to);
            if ((route.meta && route.meta.refresh) || refresh || newWindow) {
                window.open(route.href, (newWindow) ? '_blank' : '_self');
            } else {
                Routes.router.push(to);
            }
        }
    }

    /**
     * Add additional arguments to the current url.
     * Specific arguments can also be removed from the url by setting them to `undefined`.
     * @param additionalArguments
     * @param preventPageReload If true the new location will be pushed to the history (and therefore changing the url)
     */
    public static addQueryArguments(additionalArguments: LocationQuery, preventPageReload: boolean = false): void {
        let newQuery = Object.assign({}, Routes.currentRoute.query);
        newQuery = Object.assign(newQuery, additionalArguments);

        if (preventPageReload) {
            const resolvedRoute = Routes.resolve({name: Routes.currentRoute.name, query: newQuery});
            window.history.pushState({}, null, resolvedRoute.href);
        } else {
            Routes.resolveLink({name: Routes.currentRoute.name, query: newQuery});
        }
    }

    /**
     * Resolve the given route location.
     * This will not move or redirect to the route, just return the resolved route including the `href`.
     * To actually go to the given route you can use Routes.resolveLink.
     * @param to The link to resolve. Either a string or RouteLocation object.
     */
    public static resolve(to: RouteLocationRaw) {
        if (typeof to === 'string' && to.startsWith('call::')) {
            return null;
        }
        if (to instanceof Object && Routes.currentRoute.params.locale) {
            const toTemp = <any>to;
            if (!toTemp.params) {
                toTemp.params = {};
            }
            toTemp.params.locale = Routes.currentRoute.params.locale;
        }

        return Routes.router.resolve(to ?? '');
    }

    /**
     * Refresh the current page.
     */
    public static refreshPage(): void {
        Routes.routerInst.go(0);
    }

    /**
     * Go back the given number of pages in history. By default, it goes back one page.
     */
    public static back(delta: number = 1): void {
        Routes.routerInst.go(-delta);
    }

    /**
     * Find a RouterConfig that matches the given name. The first match will be returned
     * @param name The name of the RouterConfig to find.
     * @param useDeepSearch Whether to look inside children as well.
     * @param routes Optional list of routes to search through If omitted the global routes will be used.
     * @returns The first route that matches the given name or null if no route matches the name.
     */
    public static findRouteByName(name: string, useDeepSearch: boolean = true, routes: readonly RouteRecordRaw[] = null): any {
        if (name === undefined || name === null) {    // Otherwise it might return a route without name
            return null;
        }
        if (!routes) {
            routes = Routes.routerInst.options.routes;
        }

        let route: any = null;
        for (let i: number = 0; i < routes.length; i++) {
            if (routes[i].name === name) {    // Route found
                route = routes[i];
                break;
            } else if (useDeepSearch && routes[i].children) {    // Check children of route
                route = Routes.findRouteByName(name, true, routes[i].children);
                if (route) {
                    break;
                }
            }
        }

        return route;
    }

    /**
     * beforeEach handler
     */
    private static handleBeforeEach(to: RouteLocation, from: RouteLocation): boolean | RouteLocationRaw {
        // Reset target route:
        if (Store.app.targetRoute && to.name != 'seoHome') {   // Home is the pages that needs to handle the login and additional targetRoute
            Store.app.targetRoute = null;
        }

        if (Routes.redirectCounter > 20) {
            Routes.redirectCounter = 0;
            return {name: '400', state: {message: 'Too many redirects!'}};
        }

        Routes.redirectCounter++;

        // Check access:
        const result: true | RouteLocationRaw = Routes.checkAccess(to);

        if (result !== true && Routes.resolve(result).name == 'seoHome') {    // Being forcefully redirected to homepage (meaning the user wasn't logged in)
            // Remember the target, will redirect to the home page where the login modal will be opened.
            Store.app.targetRoute = to.fullPath;
        }

        // Prefix target with same locale prefix as prev route
        // TODO: Might be better to add the prefix to every route so links actually point already to the correct path
        if (from.params.locale && !to.path.startsWith('/' + from.params.locale)) {
            return {path: '/' + from.params.locale + to.path, params: to.params, query: to.query};
        } else {
            return result;
        }
    }

    /**
     * afterEach handler
     */
    private static handleAfterEach(to: RouteLocation, from: RouteLocation): void {
        Routes.redirectCounter = 0; // Reset redirect counter

        window.scrollTo(0, 0); // Scroll back to top;
        Store.app.sideMenuOpened = false;

        // Check if coming from e-mail verification link:
        if (to.query.verified) {
            Store.app.showVerifiedAlert = true;

            if (!Store.user.user) {
                Main.app.openLoginModal();
            } else if (!Store.user.hasRoles(Roles.VERIFIED_USER)) {
                // We need to refresh the user
                Store.app.targetRoute = '/dashboard';
                Store.user.refreshUser();
                return;
            }
        }

        if (Store.app.showVerifiedAlert) {
            Main.app.alert.show(Main.trans.t(Lang.t.app.verificationAlert.message), Main.trans.t(Lang.t.app.verificationAlert.title), Main.trans.t(Lang.t.app.labels.ok));
            Store.app.showVerifiedAlert = false;
        }

        SegmentHelper.trackPageView();
    }

    private static handleAuthChanged(newValue: AuthDTO, oldValue: AuthDTO): void {
        // Go to the target route if set:
        const targetRoute: string = Store.app.targetRoute;
        if (targetRoute) {
            Store.app.targetRoute = null;   // Reset
            Routes.resolveLink(targetRoute, false, true);
        } else if (oldValue == null) {    // User just logged in
            // Goto dashboard:
            Routes.resolveLink({name: 'dashboard'}, false, true);
        } else if (newValue == null) {    // User just logged out
            // Goto homepage:
            Routes.resolveLink({name: 'seoHome'}, false, true);
        } else {  // The user object, it's roles and or permissions changed
            // Refresh the current page to check if permissions are still valid:
            Routes.refreshPage();
        }
    }

    /**
     * Create a route record group, which has the option to add children.
     */
    private static group(routeRaw: RouteRecordRaw): RouteRecordRaw & { addChild: (routeChildRaw: RouteRecordRaw) => RouteRecordRaw } {
        // Create an addChild method:
        const routeRawPlus = <RouteRecordRaw & { addChild: (routeChildRaw: RouteRecordRaw) => RouteRecordRaw }>routeRaw;
        routeRawPlus.addChild = (routeChildRaw: RouteRecordRaw): RouteRecordRaw => {
            routeChildRaw = Routes.group(routeChildRaw);
            if (!routeRaw.children) {
                routeRaw.children = [];
            }
            routeRaw.children.push(routeChildRaw);
            return routeChildRaw;
        };
        return routeRawPlus;
    }

    /**
     * The routes for the app.
     */
    private static getRoutes(): RouteRecordRaw[] {  // Needs to be a getter to have the translations working

        const routes: RouteRecordRaw[] = [];

        const prefixPath: string = '/:locale(' + Object.keys(Lang.localesNative).join('|') + ')?';

        const main = Routes.group({
            path: prefixPath + '/',
            component: Layout,
        });
        routes.push(main);

        {   // USER
            main.addChild({
                path: 'user-details',
                name: 'userDetails',
                component: () => import('@/app/views/pages/user/UserEditPage.vue'),
                meta: {
                    omitVerification: true,
                    roles: [Roles.SYSTEM_USER],
                    breadcrumb: '$dashboard/#title',
                    title: Translations.instance.t(Lang.t.pages.userDetails.title),
                },
            });
            main.addChild({
                path: 'user-update-mail',
                name: 'userEditMail',
                component: () => import('@/app/views/pages/user/UserEmailEditPage.vue'),
                meta: {
                    omitVerification: true,
                    roles: [Roles.SYSTEM_USER],
                    breadcrumb: '$dashboard/#title',
                    title: Translations.instance.t(Lang.t.pages.userDetails.changeEmail.title),
                },
            });
            main.addChild({
                path: 'register-company',
                name: 'registerCompany',
                component: () => import('@/app/views/pages/company/CompanyCreatePage.vue'),
                meta: {
                    hideSideMenu: true,
                    hideTopMenu: true,
                    omitVerification: true,
                    roles: [Roles.SYSTEM_USER],
                    gate: Gate.needsCompanyRegistration,
                },
            });
            main.addChild({
                path: 'verify-mail',
                name: 'verifyMail',
                component: () => import('@/app/views/pages/user/VerifyEmailPage.vue'),
                meta: {
                    hideSideMenu: true,
                    omitVerification: true,
                    roles: [Roles.SYSTEM_USER],
                    gate: Gate.checkUnverified,
                },
            });
            main.addChild({
                path: 'updated-terms',
                name: 'userTermsEdit',
                component: () => import('@/app/views/pages/user/TermsEditPage.vue'),
                meta: {
                    hideSideMenu: true,
                    hideTopMenu: true,
                    omitVerification: true,
                    roles: [Roles.SYSTEM_USER],
                    gate: Gate.userTermsAccepted,
                },
            });
        }

        // Dashboard; content is based on type of user
        const routeRecord: any = {
            path: 'dashboard',
            name: 'dashboard',
            redirect: null,
            component: null,
            meta: {
                roles: [Roles.SYSTEM_USER],
                breadcrumb: '#title',
                title: Translations.instance.t(Lang.t.app.menus.home),
            },
        };

        if (Store.user.isAdmin) {
            routeRecord.component = () => import('@/app/views/pages/admin/DashboardPage.vue');
        } else if (Store.user.isBuyer) {
            routeRecord.component = () => import('@/app/views/pages/buyer/DashboardPage.vue');
        } else if (Store.user.isSeller) {
            routeRecord.component = () => import('@/app/views/pages/seller/DashboardPage.vue');
        } else if (!Store.user?.user?.termsAccepted) {
            routeRecord.redirect = {name: 'userTermsEdit'};
        } else if (Store.user.user) {
            routeRecord.redirect = {name: 'registerCompany'};
        }
        main.addChild(routeRecord);

        {   // COMPANY
            main.addChild({
                path: 'company/profile-details',
                name: 'companyProfileEdit',
                component: () => import('@/app/views/pages/company/ProfileEditPage.vue'),
                meta: {
                    roles: [Roles.COMPANY_USER, Roles.MANUFY_USER],
                    breadcrumb: '$dashboard/#title',
                    title: Translations.instance.t(Lang.t.app.menus.publicProfile),
                },
            });
            main.addChild({
                path: 'company/contact-details',
                name: 'companyContactEdit',
                component: () => import('@/app/views/pages/company/ContactEditPage.vue'),
                meta: {
                    roles: [Roles.COMPANY_USER, Roles.MANUFY_USER],
                    breadcrumb: '$dashboard/#title',
                    title: Translations.instance.t(Lang.t.app.menus.contactInfo),
                },
            });
            main.addChild({
                path: 'company/legal-details',
                name: 'companyLegalDetailsEdit',
                component: () => import('@/app/views/pages/company/LegalDetailsEditPage.vue'),
                meta: {
                    roles: [Roles.COMPANY_USER, Roles.MANUFY_USER],
                    breadcrumb: '$dashboard/#title',
                    title: Translations.instance.t(Lang.t.app.menus.legalInfo),
                },
            });
            main.addChild({
                path: 'company/media',
                name: 'companyMediaEdit',
                component: () => import('@/app/views/pages/company/MediaEditPage.vue'),
                meta: {
                    roles: [Roles.SELLER_USER, Roles.MANUFY_USER],
                    breadcrumb: '$dashboard/#title',
                    title: Translations.instance.t(Lang.t.pages.company.media.title),
                },
            });
            main.addChild({
                path: 'company/invoices',
                name: 'companyInvoiceList',
                component: () => import('@/app/views/pages/company/InvoiceListPage.vue'),
                meta: {
                    roles: [Roles.COMPANY_USER, Roles.MANUFY_USER],
                    breadcrumb: '$dashboard/#title',
                    title: Translations.instance.t(Lang.t.pages.company.invoices.title),
                },
            });
            main.addChild({
                path: 'company/locations',
                name: 'companyLocationList',
                component: () => import('@/app/views/pages/company/LocationListPage.vue'),
                meta: {
                    roles: [Roles.COMPANY_USER, Roles.MANUFY_USER],
                    breadcrumb: '$dashboard/#title',
                    title: Translations.instance.t(Lang.t.pages.company.locations.title),
                },
            });
            main.addChild({
                path: 'company/location/:uuid?',
                name: 'companyLocation',
                component: () => import('@/app/views/pages/company/LocationEditPage.vue'),
                meta: {
                    roles: [Roles.COMPANY_USER, Roles.MANUFY_USER],
                    breadcrumb: '$companyLocationList/*title',
                },
            });
            main.addChild({
                path: 'purchase-orders',
                name: 'purchaseOrderList',
                component: () => import('@/app/views/pages/company/PurchaseOrderListPage.vue'),
                meta: {
                    roles: [Roles.COMPANY_USER, Roles.MANUFY_USER],
                    breadcrumb: '$dashboard/#title',
                    title: Translations.instance.t(Lang.t.pages.company.orders.title),
                },
            });
            main.addChild({
                path: 'company/users',
                name: 'companyUserList',
                component: () => import('@/app/views/pages/company/UserListPage.vue'),
                meta: {
                    roles: [Roles.COMPANY_USER, Roles.MANUFY_USER],
                    breadcrumb: '$dashboard/#title',
                    title: Translations.instance.t(Lang.t.app.menus.team),
                },
            });
            main.addChild({
                path: 'company/users/invite',
                name: 'companyUserInvitationCreate',
                component: () => import('@/app/views/pages/company/UserInvitationCreatePage.vue'),
                meta: {
                    roles: [Roles.COMPANY_USER, Roles.MANUFY_USER],
                    breadcrumb: '$companyUserList/#title',
                    title: Translations.instance.t(Lang.t.pages.company.users.invite),
                },
            });
            main.addChild({
                path: 'company/email-preferences',
                name: 'companyNotificationList',
                component: () => import('@/app/views/pages/company/NotificationListPage.vue'),
                meta: {
                    roles: [Roles.COMPANY_USER, Roles.MANUFY_USER],
                    breadcrumb: '$dashboard/#title',
                    title: Translations.instance.t(Lang.t.pages.company.notifications.title),
                },
            });
            main.addChild({
                path: 'chat',
                name: 'chat',
                component: () => import('@/app/views/pages/company/ChatPage.vue'),
                meta: {
                    roles: [Roles.COMPANY_USER, Roles.MANUFY_USER],
                    fullHeight: true,
                },
            });
            main.addChild({
                path: 'subscription/pending',
                name: 'companySubscriptionPending',
                component: () => import('@/app/views/pages/company/SubscriptionPendingPage.vue'),
                meta: {
                    hideSideMenu: true,
                    hideTopMenu: true,
                },
            });
            main.addChild({
                path: 'company/subscription',
                name: 'subscriptionRead',
                component: () => import('@/app/views/pages/company/SubscriptionReadPage.vue'),
                meta: {
                    hideSideMenu: true,
                    roles: [Roles.BUYER_TIER_1, Roles.SELLER_TIER_1],
                },
            });
        }

        {   // BUYER
            main.addChild({
                path: 'projects',
                name: 'projectList',
                component: () => import('@/app/views/pages/buyer/ProjectListPage.vue'),
                meta: {
                    roles: [Roles.BUYER_USER],
                    breadcrumb: '$dashboard/#title',
                    title: Translations.instance.t(Lang.t.pages.buyerDashboard.projects.header),
                },
            });
            main.addChild({
                path: 'project/:uuid',
                name: 'projectRead',
                component: () => import('@/app/views/pages/buyer/ProjectReadPage.vue'),
                meta: {
                    roles: [Roles.BUYER_USER],
                    breadcrumb: '$projectList/*name',
                },
            });
            main.addChild({
                path: 'project/:uuid/edit',
                name: 'projectEdit',
                component: () => import('@/app/views/pages/buyer/ProjectEditPage.vue'),
                meta: {
                    roles: [Roles.BUYER_USER],
                    breadcrumb: '$projectList/*name',
                },
            });
            main.addChild({
                path: 'project/service/:uuid/edit',
                name: 'projectServiceEdit',
                component: () => import('@/app/views/pages/buyer/ProjectServiceEditPage.vue'),
                meta: {
                    roles: [Roles.BUYER_USER],
                    breadcrumb: '$projectList/*name',
                },
            });
            main.addChild({
                path: 'project/:projectUuid/service',
                name: 'projectServiceCreate',
                component: () => import('@/app/views/pages/buyer/ProjectServiceEditPage.vue'),
                meta: {
                    roles: [Roles.BUYER_USER],
                    breadcrumb: '$projectList/*name',
                },
            });
            main.addChild({
                path: 'project/product/:uuid/edit',
                name: 'projectProductEdit',
                component: () => import('@/app/views/pages/buyer/ProjectProductEditPage.vue'),
                meta: {
                    roles: [Roles.BUYER_USER],
                    breadcrumb: '$projectList/*name',
                },
            });
            main.addChild({
                path: 'project/:projectUuid/product',
                name: 'projectProductCreate',
                component: () => import('@/app/views/pages/buyer/ProjectProductEditPage.vue'),
                meta: {
                    roles: [Roles.BUYER_USER],
                    breadcrumb: '$projectList/*name',
                },
            });
            main.addChild({
                path: 'buyer/reviews',
                name: 'buyerReviewList',
                component: () => import('@/app/views/pages/buyer/ReviewListPage.vue'),
                meta: {
                    roles: [Roles.BUYER_USER],
                    breadcrumb: '$dashboard/#title',
                    title: Translations.instance.t(Lang.t.app.menus.reviews),
                }
            });
            main.addChild({
                path: 'buyer/subscriptions',
                name: 'buyerSubscriptionList',
                component: () => import('@/app/views/pages/buyer/SubscriptionListPage.vue'),
                meta: {
                    hideSideMenu: true,
                    roles: [Roles.BUYER_TIER_0],
                },
            });
        }

        {   // SELLER
            main.addChild({
                path: 'seller/projects',
                name: 'sellerProjectList',
                component: () => import('@/app/views/pages/seller/ProjectListPage.vue'),
                meta: {
                    roles: [Roles.SELLER_USER],
                    breadcrumb: '$dashboard/#title',
                    title: Translations.instance.t(Lang.t.pages.sellerDashboard.projects.header),
                },
            });
            main.addChild({
                path: 'seller/products',
                name: 'sellerProductList',
                component: () => import('@/app/views/pages/seller/ProductListPage.vue'),
                meta: {
                    roles: [Roles.SELLER_USER],
                    breadcrumb: '$dashboard/#title',
                    title: Translations.instance.t(Lang.t.pages.sellerDashboard.products.title),
                },
            });
            main.addChild({
                path: 'seller/product/:uuid',
                name: 'sellerProductRead',
                component: () => import('@/app/views/pages/seller/ProductReadPage.vue'),
                meta: {
                    roles: [Roles.SELLER_USER],
                    breadcrumb: '$sellerProductList/*title',
                },
            });
            main.addChild({
                path: 'seller/product',
                name: 'sellerProductCreate',
                component: () => import('@/app/views/pages/seller/ProductEditPage.vue'),
                meta: {
                    roles: [Roles.SELLER_USER],
                    breadcrumb: '$sellerProductList/*title',
                },
            });
            main.addChild({
                path: 'seller/product/:uuid/edit',
                name: 'sellerProductEdit',
                component: () => import('@/app/views/pages/seller/ProductEditPage.vue'),
                meta: {
                    roles: [Roles.SELLER_USER],
                    breadcrumb: '$sellerProductList/*title',
                },
            });
            main.addChild({
                path: 'seller/services',
                name: 'sellerServiceList',
                component: () => import('@/app/views/pages/seller/ServiceListPage.vue'),
                meta: {
                    roles: [Roles.SELLER_USER],
                    breadcrumb: '$dashboard/#title',
                    title: Translations.instance.t(Lang.t.pages.sellerDashboard.services.title),
                },
            });
            main.addChild({
                path: 'seller/fabrics',
                name: 'catalogueFabricList',
                component: () => import('@/app/views/pages/seller/FabricListPage.vue'),
                meta: {
                    roles: [Roles.SELLER_USER],
                    breadcrumb: '$dashboard/#title',
                    title: Translations.instance.t(Lang.t.pages.sellerDashboard.fabrics.title),
                },
            });
            main.addChild({
                path: 'seller/fabric/:uuid',
                name: 'catalogueFabricRead',
                component: () => import('@/app/views/pages/seller/FabricReadPage.vue'),
                meta: {
                    roles: [Roles.SELLER_USER],
                    breadcrumb: '$catalogueFabricList/*title',
                },
            });
            main.addChild({
                path: 'seller/fabric',
                name: 'catalogueFabricCreate',
                component: () => import('@/app/views/pages/seller/FabricEditPage.vue'),
                meta: {
                    roles: [Roles.SELLER_USER],
                    breadcrumb: '$catalogueFabricList/*title',
                },
            });
            main.addChild({
                path: 'seller/fabric/:uuid/edit',
                name: 'catalogueFabricEdit',
                component: () => import('@/app/views/pages/seller/FabricEditPage.vue'),
                meta: {
                    roles: [Roles.SELLER_USER],
                    breadcrumb: '$catalogueFabricList/*title',
                },
            });
            main.addChild({
                path: 'seller/service/:uuid',
                name: 'sellerServiceRead',
                component: () => import('@/app/views/pages/seller/ServiceReadPage.vue'),
                meta: {
                    roles: [Roles.SELLER_USER],
                    breadcrumb: '$sellerServiceList/*title',
                },
            });
            main.addChild({
                path: 'seller/service',
                name: 'sellerServiceCreate',
                component: () => import('@/app/views/pages/seller/ServiceEditPage.vue'),
                meta: {
                    roles: [Roles.SELLER_USER],
                    breadcrumb: '$sellerServiceList/*title',
                },
            });
            main.addChild({
                path: 'seller/service/:uuid/edit',
                name: 'sellerServiceEdit',
                component: () => import('@/app/views/pages/seller/ServiceEditPage.vue'),
                meta: {
                    roles: [Roles.SELLER_USER],
                    breadcrumb: '$sellerServiceList/*title',
                },
            });
            main.addChild({
                path: 'seller/preferences',
                name: 'sellerFiltersEdit',
                component: () => import('@/app/views/pages/seller/FiltersEditPage.vue'),
                meta: {
                    roles: [Roles.SELLER_USER, Roles.MANUFY_USER],
                    breadcrumb: '$dashboard/#title',
                    title: Translations.instance.t(Lang.t.pages.sellerFilters.title),
                },
            });
            main.addChild({
                path: 'seller/subscriptions',
                name: 'sellerSubscriptionList',
                component: () => import('@/app/views/pages/seller/SubscriptionListPage.vue'),
                meta: {
                    hideSideMenu: true,
                    roles: [Roles.SELLER_TIER_0],
                },
            });
            main.addChild({
                path: 'seller/manufy-values',
                name: 'sellerManufyValueList',
                component: () => import('@/app/views/pages/seller/ManufyValueListPage.vue'),
                meta: {
                    roles: [Roles.SELLER_USER, Roles.MANUFY_USER],
                    breadcrumb: '$dashboard/#title',
                    title: Translations.instance.t(Lang.t.app.labels.manufyValues),
                },
            });
        }

        {   // ADMIN
            main.addChild({
                path: 'admin/sellers',
                name: 'adminSellerList',
                component: () => import('@/app/views/pages/admin/SellerListPage.vue'),
                meta: {
                    roles: [Roles.MANUFY_USER],
                    breadcrumb: '$dashboard/#title',
                    title: Translations.instance.t(Lang.t.app.menus.sellers),
                },
            });
            main.addChild({
                path: 'admin/seller/:uuid?',
                name: 'adminSellerRead',
                component: () => import('@/app/views/pages/admin/SellerReadPage.vue'),
                meta: {
                    roles: [Roles.MANUFY_USER],
                    breadcrumb: '$adminSellerList/*sellerName',
                },
            });
            main.addChild({
                path: 'admin/buyers',
                name: 'adminBuyerList',
                component: () => import('@/app/views/pages/admin/BuyerListPage.vue'),
                meta: {
                    roles: [Roles.MANUFY_USER],
                    breadcrumb: '$dashboard/#title',
                    title: Translations.instance.t(Lang.t.app.menus.buyers),
                },
            });
            main.addChild({
                path: 'admin/buyer/:uuid?',
                name: 'adminBuyerRead',
                component: () => import('@/app/views/pages/admin/BuyerReadPage.vue'),
                meta: {
                    roles: [Roles.MANUFY_USER],
                    breadcrumb: '$adminBuyerList/*buyerName',
                },
            });
            main.addChild({
                path: 'admin/onboardings',
                name: 'adminOnboardingList',
                component: () => import('@/app/views/pages/admin/OnboardingListPage.vue'),
                meta: {
                    roles: [Roles.MANUFY_USER],
                    breadcrumb: '$dashboard/#title',
                    title: Translations.instance.t(Lang.t.app.menus.onboarding),
                },
            });
            main.addChild({
                path: 'admin/onboarding/:uuid?',
                name: 'adminOnboardingRead',
                component: () => import('@/app/views/pages/admin/OnboardingReadPage.vue'),
                meta: {
                    roles: [Roles.MANUFY_USER],
                    breadcrumb: '$adminOnboardingList/*companyName',
                },
            });
            main.addChild({
                path: 'admin/projects',
                name: 'adminProjectList',
                component: () => import('@/app/views/pages/admin/ProjectListPage.vue'),
                meta: {
                    roles: [Roles.MANUFY_USER],
                    breadcrumb: '$dashboard/#title',
                    title: Translations.instance.t(Lang.t.app.menus.projects),
                },
            });
            main.addChild({
                path: 'admin/project/:uuid?',
                name: 'adminProjectRead',
                component: () => import('@/app/views/pages/admin/ProjectReadPage.vue'),
                meta: {
                    roles: [Roles.MANUFY_USER],
                    breadcrumb: '$adminProjectList/*projectName',
                },
            });
            main.addChild({
                path: 'admin/products',
                name: 'adminProductList',
                component: () => import('@/app/views/pages/admin/RfqProductListPage.vue'),
                meta: {
                    roles: [Roles.MANUFY_USER],
                    breadcrumb: '$dashboard/#title',
                    title: Translations.instance.t(Lang.t.app.menus.products),
                },
            });
            main.addChild({
                path: 'admin/product/:uuid?',
                name: 'adminProductRead',
                component: () => import('@/app/views/pages/admin/RfqProductReadPage.vue'),
                meta: {
                    roles: [Roles.MANUFY_USER],
                    breadcrumb: '$adminProductList/*productName',
                },
            });
            main.addChild({
                path: 'admin/rfq/services',
                name: 'adminRfqServiceList',
                component: () => import('@/app/views/pages/admin/RfqServiceListPage.vue'),
                meta: {
                    roles: [Roles.MANUFY_USER],
                    breadcrumb: '$dashboard/#title',
                    title: Translations.instance.t(Lang.t.app.menus.products),
                },
            });
            main.addChild({
                path: 'admin/project-reviews',
                name: 'adminRfqReviewList',
                component: () => import('@/app/views/pages/admin/RfqReviewList.vue'),
                meta: {
                    roles: [Roles.MANUFY_USER],
                    breadcrumb: '$dashboard/#title',
                    title: Translations.instance.t(Lang.t.app.menus.rfqReviews),
                },
            });
            main.addChild({
                path: 'admin/purchase-orders',
                name: 'adminPurchaseOrderList',
                component: () => import('@/app/views/pages/admin/PurchaseOrderListPage.vue'),
                meta: {
                    roles: [Roles.MANUFY_USER],
                    breadcrumb: '$dashboard/#title',
                    title: Translations.instance.t(Lang.t.app.menus.purchaseOrders),
                },
            });
            main.addChild({
                path: 'admin/purchase-order/:uuid?',
                name: 'adminPurchaseOrderRead',
                component: () => import('@/app/views/pages/admin/PurchaseOrderReadPage.vue'),
                meta: {
                    roles: [Roles.MANUFY_USER],
                    breadcrumb: '$adminPurchaseOrderList/*productName',
                },
            });
            main.addChild({
                path: 'admin/reviews',
                name: 'adminReviewList',
                component: () => import('@/app/views/pages/admin/ReviewListPage.vue'),
                meta: {
                    roles: [Roles.MANUFY_USER],
                    breadcrumb: '$dashboard/#title',
                    title: Translations.instance.t(Lang.t.app.menus.reviews),
                },
            });
            main.addChild({
                path: 'admin/review/:uuid?',
                name: 'adminReviewRead',
                component: () => import('@/app/views/pages/admin/ReviewReadPage.vue'),
                meta: {
                    roles: [Roles.MANUFY_USER],
                    breadcrumb: '$adminReviewList/*reviewTitle',
                },
            });
            main.addChild({
                path: 'admin/catalogue-products',
                name: 'adminCatalogueProductList',
                component: () => import('@/app/views/pages/admin/CatalogueProductListPage.vue'),
                meta: {
                    roles: [Roles.MANUFY_USER],
                    breadcrumb: '$dashboard/#title',
                    title: Translations.instance.t(Lang.t.pages.admin.catalogProducts.title),
                },
            });
            main.addChild({
                path: 'admin/catalogue-product/:uuid?',
                name: 'adminCatalogueProductRead',
                component: () => import('@/app/views/pages/admin/CatalogueProductReadPage.vue'),
                meta: {
                    roles: [Roles.MANUFY_USER],
                    breadcrumb: '$adminCatalogueProductList/*productName',
                },
            });
            main.addChild({
                path: 'admin/media',
                name: 'adminMediaList',
                component: () => import('@/app/views/pages/admin/MediaListPage.vue'),
                meta: {
                    roles: [Roles.MANUFY_USER],
                    breadcrumb: '$dashboard/#title',
                    title: Translations.instance.t(Lang.t.app.menus.media),
                },
            });
            main.addChild({
                path: 'admin/users',
                name: 'adminUserList',
                component: () => import('@/app/views/pages/admin/UserListPage.vue'),
                meta: {
                    roles: [Roles.MANUFY_USER],
                    breadcrumb: '$dashboard/#title',
                    title: Translations.instance.t(Lang.t.app.menus.users),
                },
            });
            main.addChild({
                path: 'admin/user/:uuid?',
                name: 'adminUserRead',
                component: () => import('@/app/views/pages/admin/UserReadPage.vue'),
                meta: {
                    roles: [Roles.MANUFY_USER],
                    breadcrumb: '$adminUserList/*userName',
                },
            });
            main.addChild({
                path: 'admin/admins',
                name: 'adminAdminList',
                component: () => import('@/app/views/pages/admin/AdminListPage.vue'),
                meta: {
                    roles: [Roles.MANUFY_SUPER],
                    breadcrumb: '$dashboard/#title',
                    title: Translations.instance.t(Lang.t.app.menus.admins),
                },
            });
            main.addChild({
                path: 'admin/admin/:uuid?',
                name: 'adminAdminRead',
                component: () => import('@/app/views/pages/admin/UserReadPage.vue'),
                meta: {
                    roles: [Roles.MANUFY_USER],
                    breadcrumb: '$adminAdminList/*userName',
                },
            });
            main.addChild({
                path: 'admin/admin/create',
                name: 'adminAdminCreate',
                component: () => import('@/app/views/pages/admin/AdminCreatePage.vue'),
                meta: {
                    roles: [Roles.MANUFY_SUPER],
                    breadcrumb: '$adminAdminList/#title',
                    title: Translations.instance.t(Lang.t.pages.admin.createAdmin.title),
                },
            });

            main.addChild({
                path: 'admin/product-offers',
                name: 'adminProductOfferList',
                component: () => import('@/app/views/pages/admin/ProductOfferListPage.vue'),
                meta: {
                    roles: [Roles.MANUFY_USER],
                    breadcrumb: '$dashboard/#title',
                    title: Translations.instance.t(Lang.t.pages.admin.productsOffers.title),
                },
            });

            main.addChild({
                path: 'admin/services',
                name: 'adminServiceList',
                component: () => import('@/app/views/pages/admin/ServiceListPage.vue'),
                meta: {
                    roles: [Roles.MANUFY_USER],
                    breadcrumb: '$dashboard/#title',
                    title: Translations.instance.t(Lang.t.app.menus.services),
                },
            });

            main.addChild({
                path: 'admin/fabrics',
                name: 'adminFabricList',
                component: () => import('@/app/views/pages/admin/FabricListPage.vue'),
                meta: {
                    roles: [Roles.MANUFY_USER],
                    breadcrumb: '$dashboard/#title',
                    title: Translations.instance.t(Lang.t.app.menus.fabrics),
                },
            });

            main.addChild({
                path: 'admin/transactions',
                name: 'adminTransactionList',
                component: () => import('@/app/views/pages/admin/TransactionListPage.vue'),
                meta: {
                    roles: [Roles.MANUFY_USER],
                    breadcrumb: '$dashboard/#title',
                    title: Translations.instance.t(Lang.t.app.menus.transactions),
                },
            });
            main.addChild({
                path: 'admin/transaction/:uuid',
                name: 'adminTransactionRead',
                component: () => import('@/app/views/pages/admin/TransactionReadPage.vue'),
                meta: {
                    roles: [Roles.MANUFY_USER],
                    breadcrumb: '$adminTransactionList/*transactionName',
                },
            });
            main.addChild({
                path: 'admin/invoices',
                name: 'adminInvoiceList',
                component: () => import('@/app/views/pages/admin/InvoiceListPage.vue'),
                meta: {
                    roles: [Roles.MANUFY_USER],
                    breadcrumb: '$dashboard/#title',
                    title: Translations.instance.t(Lang.t.pages.company.invoices.title),
                },
            });
            main.addChild({
                path: 'admin/inquiries',
                name: 'adminProductInquiryList',
                component: () => import('@/app/views/pages/admin/ProductInquiryListPage.vue'),
                meta: {
                    roles: [Roles.MANUFY_USER],
                    breadcrumb: '$dashboard/#title',
                    title: Translations.instance.t(Lang.t.app.menus.inquiries),
                },
            });
            main.addChild({
                path: 'admin/manufy-value/documents',
                name: 'adminManufyValueDocumentList',
                component: () => import('@/app/views/pages/admin/ManufyValueDocumentListPage.vue'),
                meta: {
                    breadcrumb: '$dashboard/#title',
                    roles: [Roles.MANUFY_USER],
                    title: Translations.instance.t(Lang.t.app.menus.manufyValueApplications),
                },
            });
            main.addChild({
                path: 'admin/manufy-value/document/:uuid',
                name: 'adminManufyValueDocumentRead',
                component: () => import('@/app/views/pages/admin/ManufyValueDocumentReadPage.vue'),
                meta: {
                    roles: [Roles.MANUFY_USER],
                    breadcrumb: '$adminManufyValueDocumentList/*documentName',
                },
            });
            main.addChild({
                path: 'translations',
                name: 'translations',
                component: () => import('@/app/views/pages/admin/TranslationsPage.vue'),
                meta: {
                    roles: [Roles.MANUFY_USER],
                },
            });
            main.addChild({
                path: 'operations',
                name: 'superAdminOperations',
                component: () => import('@/app/views/pages/admin/OperationsPage.vue'),
                meta: {
                    roles: [Roles.MANUFY_SUPER],
                },
            });
            main.addChild({
                path: 'stripe/subscriptions',
                name: 'stripeSubscriptionList',
                component: () => import('@/app/views/pages/admin/StripeSubscriptionListPage.vue'),
                meta: {
                    roles: [Roles.MANUFY_SUPER],
                },
            });
            main.addChild({
                path: 'stripe/configurations',
                name: 'stripeConfigurationList',
                component: () => import('@/app/views/pages/admin/StripeConfigurationListPage.vue'),
                meta: {
                    roles: [Roles.MANUFY_SUPER],
                },
            });
            main.addChild({
                path: 'stripe/configuration/:stripeId',
                name: 'stripeConfigurationRead',
                component: () => import('@/app/views/pages/admin/StripeConfigurationReadPage.vue'),
                meta: {
                    roles: [Roles.MANUFY_SUPER],
                },
            });
            main.addChild({
                path: 'stripe/products',
                name: 'stripeProductList',
                component: () => import('@/app/views/pages/admin/StripeProductListPage.vue'),
                meta: {
                    breadcrumb: '$dashboard/#title',
                    roles: [Roles.MANUFY_SUPER],
                    title: Translations.instance.t(Lang.t.pages.admin.subscriptions.title),
                },
            });
            main.addChild({
                path: 'stripe/product/create',
                name: 'stripeProductCreate',
                component: () => import('@/app/views/pages/admin/StripeProductCreatePage.vue'),
                meta: {
                    roles: [Roles.MANUFY_SUPER],
                },
            });

            main.addChild({
                path: 'stripe/product/:stripeId',
                name: 'stripeProductRead',
                component: () => import('@/app/views/pages/admin/StripeProductReadPage.vue'),
                meta: {
                    roles: [Roles.MANUFY_SUPER],
                    breadcrumb: '$stripeProductList/*stripeId',
                },
            });
            main.addChild({
                path: 'stripe/pricing-tables',
                name: 'stripePricingTableRead',
                component: () => import('@/app/views/pages/admin/StripePricingTableReadPage.vue'),
                meta: {
                    roles: [Roles.MANUFY_SUPER],
                    breadcrumb: '$dashboard/#title',
                    title: Translations.instance.t(Lang.t.pages.admin.pricingTables.title),
                },
            });
            if (Settings.debugMode) {
                main.addChild({
                    path: 'test',
                    name: 'test',
                    component: () => import('@/app/views/pages/test/TestPage.vue'),
                    meta: {
                        omitVerification: true,
                        // roles: [Roles.MANUFY_USER],
                    },
                });
            }
        }

        {   // General accessible pages
            main.addChild({
                path: '',
                name: 'seoHome',
                alias: [
                    'login',
                    'signin',
                ],
                component: () => import('@/app/views/pages/frontoffice/HomePage.vue'),
                meta: {
                    omitVerification: true,
                    hideSideMenu: true,
                    hideTopMenu: true,
                },
            });
            main.addChild({
                path: 'google/callback',
                name: 'googleCallback',
                component: () => import('@/app/views/pages/frontoffice/HomePage.vue'),
                meta: {
                    omitVerification: true,
                    hideSideMenu: true,
                    hideTopMenu: true,
                },
            });
            main.addChild({
                path: 'password-request',
                name: 'passwordRequest',
                alias: [
                    'password-reset',
                ],
                component: () => import('@/app/views/pages/frontoffice/HomePage.vue'),
                meta: {
                    omitVerification: true,
                    hideSideMenu: true,
                    hideTopMenu: true,
                },
            });
            main.addChild({
                path: 'register',
                name: 'register',
                alias: [
                    'signup',
                ],
                component: () => import('@/app/views/pages/frontoffice/HomePage.vue'),
                meta: {
                    omitVerification: true,
                    hideSideMenu: true,
                    hideTopMenu: true,
                },
            });
            main.addChild({
                path: 'general-quotation/create',
                name: 'rfqWizard',
                component: () => import('@/app/views/pages/buyer/RFQCreatePage.vue'),
                meta: {
                    omitVerification: true,
                    hideSideMenu: true,
                },
            });
            main.addChild({
                // path: ':locale(' + Object.keys(Lang.localesNative).join('|') + ')?/manufacturers',
                path: 'manufacturers',
                name: 'sellerIndex',
                component: () => import('@/app/views/pages/frontoffice/SellerListPage.vue'),
                meta: {
                    omitVerification: true,
                    // hideSideMenu: true,
                    breadcrumb: '#title',
                    title: Translations.instance.t(Lang.t.pages.sellerIndex.title),
                },
            });
            main.addChild({
                path: 'products',
                name: 'publicProductList',
                component: () => import('@/app/views/pages/frontoffice/ProductListPage.vue'),
                meta: {
                    omitVerification: true,
                },
            });
            main.addChild({
                // path: ':locale(' + Object.keys(Lang.localesNative).join('|') + ')?/manufacturers/:slug',
                path: 'manufacturers/:uuid',
                name: 'publicSellerRead',
                component: () => import('@/app/views/pages/frontoffice/SellerReadPage.vue'),
                meta: {
                    omitVerification: true,
                    refresh: true,
                    breadcrumb: '$sellerIndex/*sellerName',
                },
            });
            main.addChild({
                // path: ':locale(' + Object.keys(Lang.localesNative).join('|') + ')?/manufacturers/:slug',
                path: 'buyers/:uuid',
                name: 'publicBuyerRead',
                component: () => import('@/app/views/pages/frontoffice/BuyerReadPage.vue'),
                meta: {
                    omitVerification: true,
                    refresh: true,
                    breadcrumb: '#title',
                },
            });
            main.addChild({
                // path: ':locale(' + Object.keys(Lang.localesNative).join('|') + ')?/products/:slug',
                path: 'products/:slug',
                name: 'seoProductRead',
                component: () => import('@/app/views/pages/frontoffice/ProductReadPage.vue'),
                meta: {
                    omitVerification: true,
                    refresh: true,
                    breadcrumb: '$publicSellerRead/*productName',
                },
            });
            main.addChild({
                path: 'services',
                name: 'publicServiceList',
                component: () => import('@/app/views/pages/frontoffice/ServiceListPage.vue'),
                meta: {
                    omitVerification: true,
                },
            });
            main.addChild({
                path: 'services/:slug',
                name: 'seoServiceRead',
                component: () => import('@/app/views/pages/frontoffice/ServiceReadPage.vue'),
                meta: {
                    omitVerification: true,
                    refresh: true,
                    breadcrumb: '$publicSellerRead/*serviceName',
                },
            });
            main.addChild({
                path: 'fabrics',
                name: 'publicFabricList',
                component: () => import('@/app/views/pages/frontoffice/FabricListPage.vue'),
                meta: {
                    omitVerification: true,
                },
            });
            main.addChild({
                path: 'fabrics/:slug',
                name: 'seoFabricRead',
                component: () => import('@/app/views/pages/frontoffice/FabricReadPage.vue'),
                meta: {
                    omitVerification: true,
                    refresh: true,
                    breadcrumb: '$publicSellerRead/*fabricName',
                },
            });
        }

        const errorPages = Routes.group({
            path: prefixPath + '/',
            component: Layout,
        });
        routes.push(errorPages);

        { // Error pages
            errorPages.addChild({
                path: '400',   // TODO: Make this work without the message in the url
                name: '400',
                component: () => import('@/app/views/pages/E400Page.vue'),
                meta: {
                    omitVerification: true,
                },
            });

            errorPages.addChild({
                path: '404',
                name: '404',
                component: () => import('@/app/views/pages/E404Page.vue'),
                meta: {
                    omitVerification: true,
                    hideSideMenu: true,
                },
            });

            errorPages.addChild({
                path: ':catchAll(.*)',
                name: 'catchAll',
                component: () => import('@/app/views/pages/E404Page.vue'),
                meta: {
                    omitVerification: true,
                    hideSideMenu: true,
                },
            });
        }

        return routes;
    }
}
